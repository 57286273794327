import React from 'react';


// CSS
import './assets/css/App.css';
import './assets/vendor/aos/aos.css';
import './assets/vendor/bootstrap/css/bootstrap.min.css';
import './assets/vendor/bootstrap-icons/bootstrap-icons.css';
import './assets/vendor/boxicons/css/boxicons.min.css';
import './assets/vendor/glightbox/css/glightbox.min.css';
import './assets/vendor/swiper/swiper-bundle.min.css';
import './assets/css/style.css';

//IMG
import logoWapp from './assets/img/printech_logo.png';

// plugins / utils
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ScrollToTop } from './assets/js/utils';
import CookieConsent from "react-cookie-consent";
import FloatingWhatsApp from 'react-floating-whatsapp';
import ReactGA from 'react-ga';

// components
import Header from './assets/js/header';
import WelcomeBanner from './assets/js/sections/welcome-banner';
import Services from './assets/js/sections/services';
import Contact from './assets/js/sections/contact';
import Footer from './assets/js/components/footer';
import TopBar from './assets/js/sections/topBar';
import AboutUs from './assets/js/sections/aboutUs';
import Statictics from './assets/js/sections/statictics';
import OurServices from './assets/js/sections/ourServices';
import Pricing from './assets/js/sections/pricing';
import Faq from './assets/js/sections/faq';
import Clients from './assets/js/sections/clients';
import Micro from './assets/js/components/plans/micro';
import SmallBusiness from './assets/js/components/plans/smallBusiness';
import MediumBusiness from './assets/js/components/plans/mediumBusiness';
import LargeBusiness from './assets/js/components/plans/largeBusiness';
import ExtraLargeBusiness from './assets/js/components/plans/extraLrageBusiness';
import CorporateBusiness from './assets/js/components/plans/corporateBusiness';
import { ToastProvider } from 'react-toast-notifications';
import TermsAndConditions from './assets/js/components/termsAndConditions';
import CookiesPolicy from './assets/js/components/cookiesPolicy';
import Epson from './assets/js/components/epson';
import Brother from './assets/js/components/brother';
import Consultanta from './assets/js/components/consultanta';

function App() {
  ReactGA.initialize('UA-75706904-1');
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <ToastProvider>
      <Router>
          <TopBar />
          <Header />
          <main id='main'>
            <ScrollToTop >
              <Switch>
                <Route exact path="/">
                  <WelcomeBanner />
                  <Services />
                  <AboutUs />
                  <Statictics />
                  <OurServices />
                  <Pricing />
                  <Faq />
                  <Contact />
                  <Clients />
                </Route>
                  
                <Route exact path="/copiatoare/micro">
                  <Micro />
                </Route>

                <Route exact path="/copiatoare/small">
                  <SmallBusiness />
                </Route>

                <Route exact path="/copiatoare/medium">
                  <MediumBusiness />
                </Route>

                <Route exact path="/copiatoare/large">
                  <LargeBusiness />
                </Route>

                <Route exact path="/copiatoare/extra-large">
                  <ExtraLargeBusiness />
                </Route>

                <Route exact path="/copiatoare/corporate">
                  <CorporateBusiness />
                </Route>

                <Route exact path="/termeni-si-conditii">
                  <TermsAndConditions />
                </Route>

                <Route exact path="/politica-cookies">
                  <CookiesPolicy />
                </Route>

                <Route exact path="/epson">
                  <Epson />
                </Route>

                <Route exact path="/brother">
                  <Brother />
                </Route>

                <Route exact path="/consultanta">
                  <Consultanta />
                </Route>

                <Route render={() => <Redirect to="/" />} />
              </Switch>
            </ScrollToTop>
          </main>

          <Footer />
      </Router>

      <CookieConsent buttonText="Accept" hideOnAccept='true'>Prin folosirea siteului, esti de acord cu politica cookies.</CookieConsent>

      <FloatingWhatsApp avatar={logoWapp} placeholder="Scrie-ne un mesaj!" chatMessage="Buna ziua! Cu ce vă putem ajuta?" statusMessage="Timp mediu de răspuns: 1 oră" phoneNumber="+40730600000" accountName="Printech"/>
    </ToastProvider>
  );
}

export default App;
