import React from 'react';

const EpsonServices = (props) => {
    return (
        <section id="featured-services" className={'featured-services'}>
            <div className={'container'} data-aos="fade-up">
                <div className={'row justify-content-center'}>
                <div className={'col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0'}>
                    <div className={'icon-box'} data-aos="fade-up" data-aos-delay="100">
                    <div className={'icon'}><i className={'bx bx-check-square'}></i></div>
                    <p className={'description'}>Stim cat de dificil este realizarea unui plan de afaceri bine pus la punct asa ca dorim sa te ajutam cu una din cele mai complicate parti. Alegerea echipamentelor a fost mereu foarte importanta la punctajele proiectelor si noi stim exact ce putem alege pentru a te ajuta.</p>
                    </div>
                </div>

                <div className={'col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0'}>
                    <div className={'icon-box'} data-aos="fade-up" data-aos-delay="200">
                    <div className={'icon'}><i className={'bx bx-tachometer'}></i></div>
                    <p className={'description'}>Performanta companiei tale depinde de perfromanta echipamentelor asa ca noi iti garantam ca vei avea cele mai perfomante dar si eficiente echipamente IT. Acestea te vor ajuta sa reduci cheltuielile si iti vor usura munca de zi cu zi.</p>
                    </div>
                </div>

                <div className={'col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0'}>
                    <div className={'icon-box'} data-aos="fade-up" data-aos-delay="300">
                    <div className={'icon'}><i className={'bx bx-file'}></i></div>
                    <p className={'description'}>Scapa de stresul si nesiguranta alegerilor pe care trebuie sa le faci. Cu Printech ai parte de cei mai buni consilieri pentru achizitionarea echimepentolor IT si vei avea in cel mai scurt timp toate documentele  gata de printare.</p>
                    </div>
                </div>
                </div>
            </div>
        </section>
    );
}

export default EpsonServices;