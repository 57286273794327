import React, { useEffect } from 'react';

const TermsAndConditions = (props) => {
    useEffect(() => {
        let homeButtons = document.querySelectorAll('#navbar .scrollto');

        homeButtons.forEach((el) => {
            el.classList.remove('active');
        });
    });

    return (
        <div className={'container'} data-aos="fade-up">
            <div className={'row'}>
                <p>
                    <b>Scopul folosirii datelor personale de către  Printech Company SRL</b><br/><br/>

                    Printech Company SRL colectează, înregistrează, organizează, stochează si utilizează pentru a administra, menține, îmbunătăți și obține feedback cu privire la serviciile pe care le oferă, precum și pentru a preveni erori și scurgeri de informații prin rețeaua IT proprie, încălcări ale legii sau termenilor contractuali.
                    <br/><br/>
                    <b>Marketing</b><br/><br/>

                    În cazul în care aveți un contract cu Printech Company SRL este posibil să va contactăm pentru a obține feedback cu privire la serviciul contractat, astfel încât să îmbunătățim calitatea acestuia.
                    <br/>
                    Printech Company SRL colectează și actualizează informațîi despre clienții serviciilor și utilizatorii site-urilor sale, precum și despre furnizorii cu care interacționează în mod regulat și poate obține aceste informații, fie direct de la dumneavoastră sau de la terțe persoane, fie automat, odată cu folosirea serviciilor Printech Company SRL prin intermediul cookie-urilor și prin rapoartele de trafic generate de către serverele care găzduiesc site-ul printechcompany.ro.
                    <br/>
                    <b>Drepturile utilizatorilor</b><br/><br/>

                    În contextul prelucrării datelor cu caracter personal, utilizatorii au următoarele drepturi:
                    <br/><br/>
                    <b>Dreptul de a fi informat:</b><br/><br/>

                    (1) În cazul în care datele cu caracter personal sunt obținute direct de la persoana vizată, operatorul este obligat să furnizeze persoanei vizate cel puțîn următoarele informații, cu excepția cazului în care această persoană posedă deja informațiile respective:
                    <br/><br/>
                    <ol>
                        <li>
                            identitatea operatorului și a reprezentantului acestuia, dacă este cazul;
                        </li>
                        <li>
                            scopul în care se face prelucrarea datelor;
                        </li>
                        <li>
                            informații suplimentare, precum: destinatarii sau categoriile de destinatari ai datelor; dacă furnizarea tuturor datelor cerute este obligatorie și consecințele refuzului de a le furniza; existența drepturilor prevăzute de prezența lege pentru persoana vizată, în special a dreptului de acces, de intervenție asupra datelor și de opoziție, precum și condițiile în care pot fi exercitate;
                        </li>
                        <li>
                            orice alte informații a căror furnizare este impusă prin dispoziție a autorității de supraveghere, ținând seama de specificul prelucrării.
                        </li>
                    </ol><br/><br/>
                    (2) În cazul în care datele nu sunt obținute direct de la persoana vizată, operatorul este obligat ca, în momentul colectării datelor sau, dacă se intenționează dezvăluirea acestora către terți, cel mai târziu până în momentul primei dezvăluiri, să furnizeze persoanei vizate cel puțin următoarele informații, cu excepția cazului în care persoana vizată posedă deja informațiile respective:
                    <br/><br/>
                    <ol>
                        <li>
                            identitatea operatorului și a reprezentantului acestuia, dacă este cazul;
                        </li>
                        <li>
                            scopul în care se face prelucrarea datelor;
                        </li>
                        <li>
                            informații suplimentare, precum: categoriile de date vizate, destinatarii sau categoriile de destinatari ai datelor, existența drepturilor prevăzute de prezența lege pentru persoană vizată, în special a dreptului de acces, de intervenție asupra datelor și de opoziție, precum și condițiile în care pot fi exercitate;
                        </li>
                        <li>
                            orice alte informații a căror furnizare este impusă prin dispoziție a autorității de supraveghere, țînând seama de specificul prelucrării.
                        </li>
                    </ol>
                    <br/>
                    (3) Prevederile alin. (2) nu se aplică în cazul în care prelucrarea datelor se face în scopuri statistice, de cercetare istorică sau științifică, ori în orice alte situațîi în care furnizarea unor asemenea informațîi se dovedește imposibilă sau ar implică un efort disproporționat față de interesul legitim care ar putea fi lezat, precum și în situațiile în care înregistrarea sau dezvăluirea datelor este expres prevăzută de lege.
                    <br/>
                    <b>Dreptul de acces la date</b><br/>

                    (1) Orice persoană vizată are dreptul de a obține de la operator, la cerere și în mod gratuit pentru o solicitare pe an, confirmarea faptului că datele care o privesc sunt sau nu sunt prelucrate de acesta. Operatorul este obligat, în situația în care prelucrează date cu caracter personal care privesc solicitantul, să comunice acestuia, împreună cu confirmarea, cel puțin următoarele:
                    <br/><br/>
                    a) informații referitoare la scopurile prelucrării, categoriile de date avute în vedere și destinatarii sau categoriile de destinatari cărora le sunt dezvăluite datele;
                    <br/>
                    b) comunicarea într-o formă inteligibilă a datelor care fac obiectul prelucrării, precum și a oricărei informații disponibile cu privire la originea datelor;
                    <br/>
                    c) informații asupra principiilor de funcționare a mecanismului prin care se efectuează orice prelucrare automată a datelor care vizează persoană respectivă;
                    <br/>
                    d) informații privind existența dreptului de intervenție asupra datelor și a dreptului de opoziție, precum și condițiile în care pot fi exercitate;
                    <br/>
                    e) informații asupra posibilității de a consulta registrul de evidență a prelucrărilor de date cu caracter personal, de a înainta plângere către autoritatea de supraveghere, precum și de a se adresa instanței pentru atacarea deciziilor operatorului, în conformitate cu dispozițiile prezenței legi.
                    <br/>
                    (2) Persoană vizată poate solicită de la operator informațiile prevăzute la alin. (1), printr-o cerere întocmită în formă scrisă, datată și semnată. În cerere solicitantul poate arată dacă dorește că informațiile să îi fie comunicate la o anumită adresă, care poate fi și de poștă electronică, sau printr-un serviciu de corespondență care să asigure că predarea i se va face numai personal.
                    <br/> <br/>
                    (3) Operatorul este obligat să comunice informațiile solicitate, în termen de 15 zile de la dată primirii cererii, cu respectarea eventualei opțiuni a solicitantului exprimate potrivit alin. (2).
                    <br/><br/>
                    <b>Dreptul de intervenție asupra datelor</b><br/><br/>

                    (1) Orice persoană vizată are dreptul de a obține de la operator, la cerere și în mod gratuit:
                    <br/>
                    <ol>
                        <li>
                            după caz, rectificarea, actualizarea, blocarea sau ștergerea datelor a căror prelucrare nu este conformă prezenței legi, în special a datelor incomplete sau inexacte;
                        </li>
                        <li>
                            după caz, transformarea în date anonime a datelor a căror prelucrare nu este conformă prezenței legi;
                        </li>
                        <li>
                            notificarea către terțîi cărora le-au fost dezvăluite datele a oricărei operațiuni efectuate conform lit. a) sau b), dacă această notificare nu se dovedește imposibilă sau nu presupune un efort disproporționat față de interesul legitim care ar putea fi lezat.
                        </li>
                    </ol>
                    <br/>
                    (2) Pentru exercitarea dreptului prevăzut la alin. (1) persoană vizată va înainta operatorului o cerere întocmită în formă scrisă, datată și semnată. În cerere solicitantul poate arată dacă dorește că informațiile să îi fie comunicate la o anumită adresa, care poate fi și de poștă electronică, sau printr-un serviciu de corespondență care să asigure că predarea i se va face numai personal.
                    <br/><br/>
                    (3) Operatorul este obligat să comunice măsurile luate în temeiul alin. (1), precum și, dacă este cazul, numele terțului căruia i-au fost dezvăluite datele cu caracter personal referitoare la persoană vizată, în termen de 15 zile de la dată primirii cererii, cu respectarea eventualei opțiuni a solicitantului exprimate potrivit alin. (2).
                    <br/><br/>
                    <b>Dreptul de opoziție</b><br/><br/>

                    (1) Persoana vizată are dreptul de a se opune, în orice moment, din motive întemeiate și legitime legate de situația sa particulară, ca date care o vizează să facă obiectul unei prelucrări, cu excepția cazurilor în care există dispozițîi legale contrare. În caz de opoziție justificată, prelucrarea nu mai poate viza datele în cauză.
                    <br/><br/>
                    (2) Persoana vizată are dreptul de a se opune în orice moment, în mod gratuit și fără nicio justificare, ca datele care o vizează să fie prelucrate în scop de marketing direct, în numele operatorului sau al unui terț sau să fie dezvăluite unor terți într-un asemenea scop.
                    <br/><br/>
                    (3) În vederea exercitării drepturilor prevăzute la alin. (1) și (2) persoană vizată va înainta operatorului o cerere întocmită în formă scrisă, datată și semnată. În cerere solicitantul poate arată dacă dorește că informațiile să îi fie comunicate la o anumită adresa, care poate fi și de poștă electronică, sau printr-un serviciu de corespondență care să asigure că predarea i se va face numai personal.
                    <br/><br/>
                    (4) Operatorul este obligat să comunice persoanei vizate măsurile luate în temeiul alin. (1) sau (2), precum și, dacă este cazul, numele terțului căruia i-au fost dezvăluite datele cu caracter personal referitoare la persoană vizată, în termen de 15 zile de la dată primirii cererii, cu respectarea eventualei opțiuni a solicitantului exprimate potrivit alin. (3).
                    <br/><br/>
                    <b>Dreptul de a nu fi supus unei decizii individuale</b><br/><br/>

                    (1) Orice persoană are dreptul de a cere și de a obține:
                    <br/>
                    <ol>
                        <li>
                            retragerea sau anularea oricărei decizii care produce efecte juridice în privința sa, adoptată exclusiv pe baza unei prelucrări de date cu caracter personal, efectuată prin mijloace automate, destinată să evalueze unele aspecte ale personalității sale, precum competență profesională, credibilitatea, comportamentul sau ori alte asemenea aspecte;
                        </li>
                        <li>
                            reevaluarea oricărei alte decizii luate în privința sa, care o afectează în mod semnificativ, dacă decizia a fost adoptată exclusiv pe baza unei prelucrări de date care întrunește condițiile prevăzute la lit. a).
                        </li>
                    </ol><br/>
                    (2) Respectându-se celelalte garanții prevăzute de prezența lege, o persoană poate fi supusă unei decizii de natură celei vizate la alin. (1), numai în următoarele situațîi:
                    <br/>
                    <ol>
                        <li>
                            decizia este luată în cadrul încheierii sau executării unui contract, cu condiția că cererea de încheiere sau de executare a contractului, introdusă de persoană vizată, să fi fost satisfăcută sau că unele măsuri adecvate, precum posibilitatea de a-și susține punctul de vedere, să garanteze apărarea propriului interes legitim;
                        </li>
                        <li>
                            decizia este autorizată de o lege care precizează măsurile ce garantează apărarea interesului legitim al persoanei vizate.
                        </li>
                    </ol>
                    <b>Dreptul la ștergerea datelor</b><br/><br/>

                    (1) Persoana vizată are dreptul de a obține din partea operatorului ștergerea datelor cu caracter personal care o privesc, fără întârzieri nejustificate, iar operatorul are obligația de a șterge datele cu caracter personal, fără întârzieri nejustificate, în cazul în care se aplică unul dintre următoarele motive:
                    <br/>
                    <ol>
                        <li>
                            datele cu caracter personal nu mai sunt necesare pentru îndeplinirea scopurilor pentru care au fost colectate sau prelucrate;
                        </li>
                        <li>
                            persoana vizată își retrage consimțământul pe baza căruia are loc prelucrarea și nu există niciun alt temei juridic pentru prelucrarea;
                        </li>
                        <li>
                            persoana vizată se opune prelucrării și nu există motive legitime care să prevaleze în ceea ce privește prelucrarea sa;
                        </li>
                        <li>
                            datele cu caracter personal au fost prelucrate ilegal;
                        </li>
                        <li>
                            datele cu caracter personal trebuie șterse pentru respectarea unei obligații legale care revine operatorului în temeiul dreptului Uniunii sau al dreptului intern sub incidența căruia se află operatorul;
                        </li>
                        <li>
                            datele cu caracter personal au fost colectate în legătură cu oferirea de servicii ale societății informaționale.
                        </li>
                    </ol>
                    <br/>
                    (2) În cazul în care operatorul a făcut publice datele cu caracter personal și este obligat, în temeiul alineatului (1), să le șteargă, operatorul, țînând seama de tehnologia disponibilă și de costul implementării, ia măsuri rezonabile, inclusiv măsuri tehnice, pentru a informa operatorii care prelucrează datele cu caracter personal că persoană vizată a solicitat ștergerea de către acești operatori a oricăror linkuri către datele respective sau a oricăror copii sau reproduceri ale acestor date cu caracter personal.
                    <br/><br/>
                    <b>Dreptul de a se adresa justiției</b><br/>

                    (1) Fără a se aduce atingere posibilității de a se adresa cu plângere autorității de supraveghere, persoanele vizate au dreptul de a se adresa justiției pentru apărarea oricăror drepturi garantate de prezența lege, care le-au fost încălcate.
                    <br/><br/>
                    (2) Orice persoană care a suferit un prejudiciu în urmă unei prelucrări de date cu caracter personal, efectuată ilegal, se poate adresa instanței competențe pentru repararea acestuia.
                    <br/><br/>
                    (3) Instanța competență este cea în a cărei rază teritorială domiciliază reclamantul. Cererea de chemare în judecată este scutită de taxa de timbru.
                    <br/><br/>
                    Pentru implementarea măsurilor tehnice și organizatorice necesare pentru păstrarea confidențialității și integrității datelor cu caracter personal, operatorul va respectă cerințele minime de securitate a prelucrărilor de date cu caracter personal elaborate de autoritatea de supraveghere potrivit stadiului tehnicii utilizate în procesul de prelucrare și de costuri, astfel încât să se asigure un nivel de securitate adecvat, în ceea ce privește riscurile pe care le reprezintă prelucrarea, precum și în ceea ce privește natura datelor care trebuie protejate.
                    <br/>
                    În acest sens, cerințele minime de securitate a prelucrărilor de date cu caracter personal acoperă următoarele aspecte:
                    <br/><br/>
                    <b>Instruirea personalului</b><br/><br/>

                    În cadrul cursurilor de pregătire a utilizatorilor operatorul este obligat să facă informarea/instruirea acestora cu privire la următoarele aspecte:
                    <br/>
                    <ol>
                        <li>
                            prevederile Legii nr. 677/2001 pentru protecția persoanelor cu privire la prelucrarea datelor cu caracter personal și liberă circulație a acestor date, la cerințele minime de securitate a prelucrărilor de date cu caracter personal;
                        </li>
                        <li>
                            riscurile pe care le comportă prelucrarea datelor cu caracter personal, în funcție de specificul activității utilizatorului.
                        </li>
                        <li>
                            păstrarea confidențialității datelor cu caracter personal, sens în care vor fi avertizați prin mesaje care vor apărea pe monitoare în timpul activității, utilizatorii având totodată și obligația de a închide sesiunea de lucru atunci când părăsesc locul de muncă.
                        </li>
                    </ol>
                    <br/>
                    <b>Folosirea computerelor</b><br/><br/>
                    Pentru menținerea securității prelucrării datelor cu caracter personal (în special împotriva virușilor informatici) operatorul va lua următoarele măsuri:
                    <br/>
                    <ol>
                        <li>
                            interzicerea folosirii de către utilizatori a programelor software care provin din surse externe sau dubioase;
                        </li>
                        <li>
                            informarea utilizatorilor în privința pericolului privind virușii informatici;
                        </li>
                        <li>
                            implementarea unor sisteme automate de devirusare și de securitate a sistemelor informatice;
                        </li>
                        <li>
                            dezactivarea, pe cât posibil, a tastei „Prinț screen”, atunci când sunt afișate pe monitor date cu caracter personal, interzicându-se astfel scoaterea la imprimantă a acestora.
                        </li>
                    </ol>
                    <b>Contact</b><br/><br/>

                    Pentru orice alte informații referitoare la colectarea, arhivarea și protejarea datelor cu caracter personal, va rugăm să ne trimiteți un e-mail la adresa office@printechcompany.ro
                    <br/><br/>
                    <b>Actualizare</b><br/><br/>

                    Aceste Condiții de Confidențialitate sunt actualizate de câte ori este nevoie. Vă rugăm să citiți periodic aceste condiții, pentru a fi la curent cu ce informații colectează, folosește și transmite Printech Company SRL.
                    <br/><br/>
                </p>
            </div>
        </div>
    );
};

export default TermsAndConditions;