import React, { useEffect } from 'react';
import Services from './sections/services';
import WelcomeBanner from './sections/welcome-banner-brother'
import AboutUs from './sections/aboutUs';
import Statictics from '../../sections/statictics';

const Brother = (props) => {
    useEffect(() => {
        let homeButtons = document.querySelectorAll('#navbar .scrollto');

        homeButtons.forEach((el) => {
            if (el.classList.contains('brother')) {
                el.classList.add('active');
            } else {
                el.classList.remove('active');
            }
        });
    });

    return (
        <>
            <WelcomeBanner />
            <Services />
            <AboutUs />
            <Statictics />
        </>
    );
};

export default Brother;