import React from 'react';
import emailjs from 'emailjs-com';
import ContactForm from '../components/contactForm';
import { useToasts } from 'react-toast-notifications';
import $ from 'jquery';

const Contact = () => {
    const { addToast } = useToasts();

    const handleSubmit = async (event) => {
      event.preventDefault();

      const spinner = $('#spinner-contact');

      if (spinner !== undefined && spinner.length > 0) {
        $(spinner[0]).removeClass('d-none');
      }

      emailjs.sendForm(
        'service_ptc2etr',
        'template_hrv5wa7',
        event.target,
        'user_ojxxcQzaxVvNok36hxj34'
      ).then(response => {
        if (response !== undefined && response.status === 200) {
          addToast('Email trimis cu succes!', {
            appearance: 'success',
            autoDismiss: true,
          });
        } else {
          addToast('Email-ul nu a putut fi trimis. Va rugam sa ne contactati telefonic!', {
            appearance: 'error',
            autoDismiss: true,
          });
        }

        if (spinner !== undefined && spinner.length > 0) {
          $(spinner[0]).addClass('d-none');
        }

      }).catch(err => {
        addToast('Email-ul nu a putut fi trimis. Va rugam sa ne contactati telefonic!', {
          appearance: 'error',
          autoDismiss: true,
        });

        if (spinner !== undefined && spinner.length > 0) {
          $(spinner[0]).addClass('d-none');
        }
      });

      event.target.reset();
    };

    return (
    <section id="contact" className={'contact'}>
      <div className={'container'} data-aos="fade-up">

        <div className={'section-title'}>
          <h2>Contact</h2>
          <h3><span>Contactează-ne</span></h3>
          <p>Ne găsești atât în Târgu-Jiu, cât și în Oradea.</p>
        </div>

        <div className={'row'} data-aos="fade-up" data-aos-delay="100">
          <div className={'col-lg-6'}>
            <div className={'info-box mb-4'}>
              <i className={'bx bx-map'}></i>
              <h3>Adresele noastre</h3>
              <p>Str. 14 Octombrie, nr.46, Târgu-Jiu, Gorj</p>
              <p>Str. George Emil Palade, nr.93, Oradea, Bihor</p>
            </div>
          </div>

          <div className={'col-lg-3 col-md-6'}>
            <div className={'info-box  mb-4'}>
              <i className={'bx bx-envelope'}></i>
              <h3>Email</h3>
              <p> office@printechcompany.ro</p><br/>
            </div>
          </div>

          <div className={'col-lg-3 col-md-6'}>
            <div className={'info-box  mb-4'}>
              <i className={'bx bx-phone-call'}></i>
              <h3>Telefon</h3>
              <p>Mobil: 0730600000</p>
              <p>Fix: 0353416464</p>
            </div>
          </div>
        </div>

        <div className={'row'} data-aos="fade-up" data-aos-delay="100">
          <div className={'col-lg-6'}>
            <iframe title='Contact Map' className={'mb-4 mb-lg-0'} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d996.8490754841661!2d23.266542661491897!3d45.034769660767765!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb23deab9064ee0ca!2sPrintech+Company!5e0!3m2!1sen!2sus!4v1458640793327" frameBorder="0" style={{border: "0", width: "100%", height: "384px",}} allowFullScreen></iframe>
          </div>

          <div className={'col-lg-6'}>
            <ContactForm handleSubmit={handleSubmit}/>
          </div>
        </div>
      </div>
    </section>
    );
}

export default Contact;