import React from "react";

const BrotherAdvantages = (props) => {
    return (
        <section id="services" className={'services'}>
            <div className={'container'} data-aos="fade-up">
                <div className={'row'}>
                    <div className={'col-lg-4 col-md-6 d-flex align-items-stretch'} data-aos="zoom-in" data-aos-delay="100">
                        <div className={'icon-box'}>
                            <div className={'icon'}><i className={'bx bx-tachometer'}></i></div>
                            <h4>Sporiți performanțele & reduceți costurile de imprimare</h4>
                            <p>Alimentare flexibilă ce vă permite să adăugați tăvi de alimentare și ieșire opționale la toată gama. Acestea oferă un timp de utilizare maxim, reducând timpul petrecut cu alimentarea hârtiei și găsirea documentelor imprimate. Cu cartușele de toner opționale ultra high yield, sporiți productivitatea reducând costul pe pagină.</p>
                        </div>
                    </div>

                    <div className={'col-lg-4 col-md-6 d-flex align-items-stretch'} data-aos="zoom-in" data-aos-delay="200">
                        <div className={'icon-box'}>
                            <div className={'icon'}><i className={'bx bx-lock-alt'}></i></div>
                            <h4>Securitate sporită</h4>
                            <p>În mediul de lucru actual, întelegem că organizațiile au cerințe de securitate înalte. Pentru a veni în întâmpinarea lor, gama L6000 integrează secure function lock 3.0, 802.1x și Internet Protocol Security (IPsec). De asemenea, gama mai include Print Archive, Secure Reset, Certificate Management și Near field communication (NFC) pentru acces prin autentificare securizată. </p>
                        </div>
                    </div>

                    <div className={'col-lg-4 col-md-6 d-flex align-items-stretch'} data-aos="zoom-in" data-aos-delay="300">
                        <div className={'icon-box'}>
                            <div className={'icon'}><i className={'bx bx-printer'}></i></div>
                            <h4>Soluții de imprimare flexibile de la Brother</h4>
                            <p>Gama L6000 este proiectată să se integreze ușor și să îndeplinească cerințele afacerii dumneavoastră. Compabile cu numeroase servicii și programe de imprimare, Brother Solutions vă permit să maximizați eficiența la imprimare și să reduceți costurile, adecvate pentru orice nevoi.</p>
                        </div>
                    </div>
                </div>

                <div className={'row'}>

                    <div className={'col-lg-4 col-md-6 d-flex align-items-stretch mt-4'} data-aos="zoom-in" data-aos-delay="100">
                        <div className={'icon-box'}>
                            <div className={'icon'}><i className={'bx bx-trending-up'}></i></div>
                            <h4>Fiabilitate sporită</h4>
                            <p>Construite pentru anduranță și îndeplinirea cerințelor unor medii de lucru cu volume mari, gama L6000 a fost reproiectată cu o construcție robustă și componente mai rezistente la uzură. </p>
                        </div>
                    </div>

                    <div className={'col-lg-4 col-md-6 d-flex align-items-stretch mt-4'} data-aos="zoom-in" data-aos-delay="200">
                        <div className={'icon-box'}>
                            <div className={'icon'}><i className={'bx bx-timer'}></i></div>
                            <h4>Flux de lucru îmbunătățit</h4>
                            <p>Gama L6000 introduce o interfață intuitivă și prietenoasă, și suport pentru servicii business cloud ce includ Dropbox și Google Drive.</p>
                        </div>
                    </div>

                    <div className={'col-lg-4 col-md-6 d-flex align-items-stretch mt-4'} data-aos="zoom-in" data-aos-delay="300">
                        <div className={'icon-box'}>
                            <div className={'icon'}><i className={'bx bxs-tree'}></i></div>
                            <h4>Eco-friendly</h4>
                            <p>Gama L6000 are certificări din partea mai multor standarde de mediu precum German Blue Angel și Nordic Swan. Astfel, nu doar că vă ajută să lucrați mai eficient, dar este și eficientă energetic, și vă ajută să reduceți impactul negativ asupra mediului înconjurător.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BrotherAdvantages;