import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ServiceModal from "../components/services/serviceModal";

const OurServices = () => {
    const [show, setShow] = useState(false);

    return (
        <section id="services" className={'services'}>
            <div className={'container'} data-aos="fade-up">
                <div className={'section-title'}>
                    <h2>Services</h2>
                    <h3><span>Serviciile</span> Oferite</h3>
                    <p>Serviciile oferite de noi sunt asigurate de o echipă de profesioniști, garantând calitatea superioară a acestora.</p>
                </div>

                <div className={'row'}>
                    <div className={'diag-wrapper mb-4 text-center'}>
                        <Button onClick={(e) => {setShow(true)}} className={'diag-button'}>Cere diagnosticare</Button>
                    </div>
                </div>

                <div className={'row'}>
                    <div className={'col-lg-4 col-md-6 d-flex align-items-stretch'} data-aos="zoom-in" data-aos-delay="100">
                        <div className={'icon-box'}>
                            <div className={'icon'}><i className={'bx bx-desktop'}></i></div>
                            <h4>Service PC și Laptop</h4>
                            <p>Toată gama de service pentru PC și laptop și reparațiile sunt făcute de către tehnicienii Printech în cadrul firmei noatre din Târgu-Jiu, permițând un control al calității excelent.</p>
                        </div>
                    </div>

                    <div className={'col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0'} data-aos="zoom-in" data-aos-delay="200">
                        <div className={'icon-box'}>
                            <div className={'icon'}><i className={'bx bx-printer'}></i></div>
                            <h4>Service Imprimante</h4>
                            <p>Asigurăm service imprimante, copiatoare, multifuncționale inkjet, laser și matriciale. Efectuăm reparații, curățare, recondiționare, reumplere tonere și cartușe.</p>
                        </div>
                    </div>

                    <div className={'col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0'} data-aos="zoom-in" data-aos-delay="300">
                        <div className={'icon-box'}>
                            <div className={'icon'}><i className={'bx bx-mouse'}></i></div>
                            <h4>Service Echipamente Birou</h4>
                            <p>Service pentru echipamente de birou, pentru desfășurarea în condiții optime a activității unei firme indiferent de mărimea acesteia.</p>
                        </div>
                    </div>

                    <div className={'col-lg-4 col-md-6 d-flex align-items-stretch mt-4'} data-aos="zoom-in" data-aos-delay="100">
                        <div className={'icon-box'}>
                            <div className={'icon'}><i className={'bx bx-memory-card'}></i></div>
                            <h4>Recuperari și Salvări de Date</h4>
                            <p>Service profesional pentru recuperarea datelor în Târgu-Jiu, acesta fiind un proces de colectare și salvare a datelor inaccesibile de pe spații de stocare corupte sau deteriorate.</p>
                        </div>
                    </div>

                    <div className={'col-lg-4 col-md-6 d-flex align-items-stretch mt-4'} data-aos="zoom-in" data-aos-delay="200">
                        <div className={'icon-box'}>
                            <div className={'icon'}><i className={'bx bx-bug'}></i></div>
                            <h4>Devirusări Profesionale</h4>
                            <p>Oferim devirusări profesionale cu ajutorul antivirusului Kaspersky. Acesta este un program dezvoltat de Kaspersky Labs. Este disponibil pentru platformele Windows, Mac și Linux.</p>
                        </div>
                    </div>

                    <div className={'col-lg-4 col-md-6 d-flex align-items-stretch mt-4'} data-aos="zoom-in" data-aos-delay="300">
                        <div className={'icon-box'}>
                            <div className={'icon'}><i className={'bx bx-sitemap'}></i></div>
                            <h4>Configurări Rețele</h4>
                            <p>Proiectare, instalare, configurare rețele și administrare rețele calculatoare. Furnizăm servicii de analiză, proiectare și administrare de rețea (mentenanță).</p>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show}>
                <Modal.Header closeButton onHide={(e) => setShow(false)}>
                    <Modal.Title>Cerere diagnosticare</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ServiceModal />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={(e) => setShow(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </section>
    );
};

export default OurServices;