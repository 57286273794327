import React, { forwardRef, useImperativeHandle, useState } from 'react';

const DefaultForm = forwardRef((props, ref) => {
    const [marca, setMarca] = useState('');
    const [defect, setDefect] = useState('');

    useImperativeHandle(
        ref,
        () => ({
            composeMessage() {
                return {'message' : 'Marca: ' + marca + '<br/Defect: ' + defect + '<br/'};
            }
        }),
    );

    return (
        <>
            <div className={'form-group'}>
                <input onChange={(e) => setMarca(e.target.value)} type="text" className={'form-control'} name="marca" id="subject" placeholder={props.textMessage} required/>
            </div>

            <div className={'form-group'}>
                <textarea onChange={(e) => setDefect(e.target.value)} className={'form-control'} name="defect" rows="5" placeholder="Descriere defect" required></textarea>
            </div>
        </>
    ); 
});

export default DefaultForm;