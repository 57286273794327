import React from "react";
import Spinner from 'react-bootstrap/Spinner';

const ContactForm = (props) => {
    return (
      <div className={'contact'}>
        <form onSubmit={props.handleSubmit} method="post" className={'php-email-form'}>
              <div className={'row'}>
                <div className={'col form-group'}>
                  <input type="text" name="name" className={'form-control'} id="name" placeholder="Nume întreg" required/>
                </div>
                <div className={'col form-group'}>
                  <input type="email" className={'form-control'} name="email" id="email" placeholder="Email" required/>
                </div>
              </div>

              <div className={'form-group'}>
                <input type="text" className={'form-control'} name="phone" id="phone" placeholder="Telefon" required/>
              </div>

              <div className={'form-group'}>
                <input type="text" className={'form-control'} name="subject" id="subject" placeholder="Subiect" required/>
              </div>

              <div className={'form-group'}>
                <textarea className={'form-control'} name="message" rows="5" placeholder="Mesaj" defaultValue={props.message} required></textarea>
              </div>
              
              <div className={'my-3'}>
                <div className={'loading'}>Loading</div>
                <div className={'error-message'}></div>
                <div className={'sent-message'}>Mesajul a fost trimis, multumim!</div>
              </div>

              <div id="spinner-contact" className={'d-none text-center my-3'}>
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                />
              </div>

              <div className={'text-center'}><button type="submit">Trimite</button></div>
        </form>
      </div>
    );
};

export default ContactForm;