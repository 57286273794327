import React, { forwardRef, useImperativeHandle, useState } from 'react';
import Form from 'react-bootstrap/Form';

const MultipleEquipments = forwardRef((props, ref) => {
    const [option, setOption] = useState('Scanner');
    const [anotherOption, setAnotherOption] = useState('');
    const [marca, setMarca] = useState('');
    const [defect, setDefect] = useState('');

    useImperativeHandle(
        ref,
        () => ({
            composeMessage() {
                return {'message' : 'Tip echipament: ' + option + 
                                    '<br/>Alt echipament (daca s-a selectat "Altul"): ' + anotherOption + 
                                    '<br/>Marca echipament: ' + marca +
                                    '<br/>Desciere defect: ' + defect + '<br/>'};
            }
        }),
    );

    return (
        <>
            <Form.Select className={'my-3'} aria-label="Alege optiunea" onChange={(e) => setOption(e.target.value)}>
                <option value="Scanner">Scanner</option>
                <option value="Fax">Fax</option>
                <option value="UPS">UPS</option>
                <option value="Altele">Altele</option>
            </Form.Select>

            <div className={'form-group'}>
                <input onChange={(e) => setAnotherOption(e.target.value)} type="text" className={'form-control'} name="marca" id="subject" placeholder="Alt Echipament (daca s-a selectat 'Alta optiune')."/>
            </div>

            <div className={'form-group'}>
                <input onChange={(e) => setMarca(e.target.value)} type="text" className={'form-control'} name="marca" id="subject" placeholder="Marca" required/>
            </div>

            <div className={'form-group'}>
                <textarea onChange={(e) => setDefect(e.target.value)} className={'form-control'} name="defect" rows="5" placeholder="Descriere defect" required></textarea>
            </div>
        </>
    ); 
});

export default MultipleEquipments;