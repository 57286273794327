import React from "react";
import brother from '../../img/clients/brother.jpg';
import hp from '../../img/clients/hp.png';
import tpling from '../../img/clients/tpling.jpg';
import dell from '../../img/clients/dell.jpg';
import kasperkey from '../../img/clients/kasperkey.png';
import canonPartner from '../../img/clients/canon-partner.jpg';
import epson from '../../img/clients/epson_logo.jpg';

const Clients = () => {
    return (
        <section id="clients" className={'clients section-bg'}>
            <div className={'container'} data-aos="zoom-in">
                <div className={'section-title'}>
                    <h3>Partenerii noștri</h3>
                </div>

                <div className={'row'}>
                    <div className={'col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center'}>
                        <img src={brother} className={'img-fluid'} alt="brother"/>
                    </div>

                    <div className={'col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center'}>
                        <img src={hp} className={'img-fluid'} alt="hp"/>
                    </div>

                    <div className={'col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center'}>
                        <img src={tpling} className={'img-fluid'} alt="tplink"/>
                    </div>

                    <div className={'col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center'}>
                        <img src={dell} className={'img-fluid'} alt="dell"/>
                    </div>

                    <div className={'col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center'}>
                        <img src={kasperkey} className={'img-fluid'} alt="kaspersky"/>
                    </div>

                    <div className={'col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center'}>
                        <img src={canonPartner} className={'img-fluid'} alt="cannon"/>
                    </div>
                </div>

                <div className={'row justify-content-center'}>
                    <div className={'col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center'}>
                        <img src={epson} className={'img-fluid'} alt="epson"/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Clients;