import React, { useRef, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import DefaultForm from './components/defaultForm';
import CommonForm from './components/commonForm';
import PcForm from './components/pcForm';
import MultipleEquipments from './components/multipleEqupmentsForm';
import emailjs from 'emailjs-com';
import $ from 'jquery';
import { useToasts } from 'react-toast-notifications';

const ServiceModal = (props) => {
    const [page, setPage] = useState('default');
    const childRef = useRef();
    const { addToast } = useToasts();

    const handleChange = (event) => {
      if (event.target.value) {
        setPage(event.target.value);
      } 
    }

    const handleSubmit = (event) => {
      event.preventDefault();

      const spinner = $('#spinner-diag');

      if (spinner !== undefined && spinner.length > 0) {
        $(spinner[0]).removeClass('d-none');
      }

      console.log(childRef.current.composeMessage().message);

      emailjs.send(
        'service_ptc2etr',
        'template_hrv5wa7',
        {
          name: event.target.name.value,
          phone: event.target.phone.value,
          email: event.target.email.value,
          subject: 'Cerere diagnostic',
          message: childRef.current.composeMessage().message
        },
        'user_ojxxcQzaxVvNok36hxj34'
      ).then(response => {
        if (response !== undefined && response.status === 200) {
          addToast('Email trimis cu succes!', {
            appearance: 'success',
            autoDismiss: true,
          });
        } else {
          addToast('Email-ul nu a putut fi trimis. Va rugam sa ne contactati telefonic!', {
            appearance: 'error',
            autoDismiss: true,
          });
        }

        if (spinner !== undefined && spinner.length > 0) {
          $(spinner[0]).addClass('d-none');
        }

        setPage('default');
      }).catch(err => {
        addToast('Email-ul nu a putut fi trimis. Va rugam sa ne contactati telefonic!', {
          appearance: 'error',
          autoDismiss: true,
        });

        if (spinner !== undefined && spinner.length > 0) {
          $(spinner[0]).addClass('d-none');
        }

        setPage('default');
      });

      event.target.reset();
    }

    return (
      <div className={'contact'}>
        <form onSubmit={handleSubmit} method="post" className={'php-email-form'}>
              <div className={'row'}>
                <div className={'col form-group'}>
                  <input type="text" name="name" className={'form-control'} id="name" placeholder="Nume intreg" required/>
                </div>
                <div className={'col form-group'}>
                  <input type="email" className={'form-control'} name="email" id="email" placeholder="Email" required/>
                </div>
              </div>

              <div className={'form-group'}>
                <input type="text" className={'form-control'} name="phone" id="phone" placeholder="Telefon" required/>
              </div>

              <Form.Select aria-label="Alege optiunea de diagnosticare" onChange={handleChange}>
                <option value="default">--Alege optiunea de diagnosticare--</option>
                <option value="laptop">Laptop defect</option>
                <option value="pc">PC defect</option>
                <option value="monitor">Monitor defect</option>
                <option value="imprimanta">Imprimanta defecta</option>
                <option value="copiator">Copiator defect</option>
                <option value="cartuse">Incarcari cartuse</option>
                <option value="echipamente_birou">Echipamente de birou defecte</option>
                <option value="personal_data">Recuperare si salvare de date</option>
                <option value="retea">Retea</option>
              </Form.Select>

              <div className={'my-3'}>
                <div className={'loading'}>Loading</div>
                <div className={'error-message'}></div>
                <div className={'sent-message'}>Mesajul a fost trimis, multumim!</div>
              </div>

              {(page === 'laptop' || page === 'monitor' || page === 'imprimanta' || page === 'copiator') && <DefaultForm ref={childRef} textMessage='Marca'/>}

              {page === 'personal_data' && <DefaultForm ref={childRef} textMessage='Recuperari si salvari date'/>}

              {(page === 'retea' || page === 'cartuse')  && <CommonForm ref={childRef} />}

              {page === 'pc' && <PcForm ref={childRef} />}

              {page === 'echipamente_birou' && <MultipleEquipments ref={childRef} />}

              <div id="spinner-diag" className={'d-none text-center my-3'}>
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                />
              </div>

              <div className={'text-center'}><button type="submit">Send</button></div>
        </form>
      </div>
    );
}

export default ServiceModal;
