import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ContactForm from '../../contactForm';
import { constants, handleSubmit } from '../../../utils';
import { useToasts } from 'react-toast-notifications';
import brother from '../../../../img/brother/brother_inchiriere.png';

const OrderBrother = (props) => {
    const { addToast } = useToasts();
    const [pageProps, setPageProps] = useState({show: false, message: ''});
    const handleClose = () => setPageProps({show: false, message: pageProps.message});
    const handleShow = (msg) => {setPageProps({show: true, message: msg})};

    const handleSubmission = async (event) => {
        await handleSubmit(event).then(response => {
          if (response === 'success') {
              addToast('Email trimis cu succes!', {
                  appearance: 'success',
                  autoDismiss: true,
              });
          } else {
            addToast('Email-ul nu a putut fi trimis. Va rugam sa ne contactati telefonic!', {
              appearance: 'error',
              autoDismiss: true,
            });
          }
        });
    }

    return (
        <section className={'pricing'}>
            <div className={'container'} data-aos="fade-up">
                <div className={'section-title'}>
                    <h2>Oferte</h2>
                </div>

                <div className={'row my-4 justify-content-center'}>

                    <div className={'col-lg-4'} data-aos="fade-up" data-aos-delay="100">
                        <div className={'box'}>
                        <h3>Multifuncțional A4 Monocrom Brother MFC-L6900DW</h3>
                        <h4><sup>€</sup>27 + TVA<span></span></h4>
                        <ul>
                            <p>A4, Automat, Printare laser, Monocrom, Multifuncțională, Eco-friendly, Securitate sporită, Fiabil</p>
                        </ul>
                        <img className={'img-fluid'} src={brother} alt='Brother MPS'/>
                        <div className={'btn-wrap'}>
                            <Button onClick={(e) => handleShow(constants.BROTHER_MESSAGE)} className={'btn-buy'}>Cere Ofertă</Button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={pageProps.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Cerere oferta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ContactForm handleSubmit={handleSubmission} message={pageProps.message}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    );
};

export default OrderBrother;