import { useEffect } from "react";
import { useLocation } from "react-router";
import emailjs from 'emailjs-com';
import $ from 'jquery';

export const appendScript = (scriptToAppend) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = true;
    document.body.appendChild(script);
}

export const removeScript = (scriptToremove) => {
    let allsuspects=document.getElementsByTagName("script");
    for (let i = allsuspects.length; i >= 0; i--){
        if (allsuspects[i] && allsuspects[i].getAttribute("src") !== null &&
            allsuspects[i].getAttribute("src").indexOf(`${scriptToremove}`) !==  -1) {
            allsuspects[i].parentNode.removeChild(allsuspects[i])
        }
    }
}

export const ScrollToTop = (props) => {
    const location = useLocation();

    useEffect(() => {
        const url = window.location.href;
        const lastSegment = url.substring(url.lastIndexOf("/") + 1);

        if (lastSegment && lastSegment.length > 1 && lastSegment[0] === '#') {
            const section = $(lastSegment);
            window.scrollTo({ behavior: 'smooth', top: $(section).offset().top})
        } else {
            window.scrollTo(0, 0);
        }
    }, [location]);

    return <>{props.children}</>
};

export const constants = {
    MICRO_1_MESSAGE: "Doresc pachetul MICRO business, la 170 de lei, pachet 1 MONOCROM",
    MICRO_2_MESSAGE: "Doresc pachetul MICRO business, la 170 de lei, pachet 2 MONOCROM",
    SMALL_1_MESSAGE: "Doresc pachetul SMALL business, la 240 de lei, pachet 1 MONOCROM",
    SMALL_2_MESSAGE: "Doresc pachetul SMALL business, la 240 de lei, pachet 2 MONOCROM",
    SMALL_3_MESSAGE: "Doresc pachetul SMALL business, la 240 de lei, pachet 3 COLOR",
    MEDIUM_1_MESSAGE: "Doresc pachetul MEDIUM business, la 350 de lei, pachet 1 MONOCROM",
    MEDIUM_2_MESSAGE: "Doresc pachetul MEDIUM business, la 350 de lei, pachet 2 MONOCROM",
    MEDIUM_3_MESSAGE: "Doresc pachetul MEDIUM business, la 350 de lei, pachet 3 COLOR",
    LARGE_1_MESSAGE: "Doresc pachetul LARGE business, la 470 de lei, pachet 1 MONOCROM",
    LARGE_2_MESSAGE: "Doresc pachetul LARGE business, la 470 de lei, pachet 2 MONOCROM",
    LARGE_3_MESSAGE: "Doresc pachetul LARGE business, la 470 de lei, pachet 3 COLOR",
    EXTRA_LARGE_1_MESSAGE: "Doresc pachetul EXTRA LARGE business, la 780 de lei, pachet 1 MONOCROM",
    EXTRA_LARGE_2_MESSAGE: "Doresc pachetul EXTRA LARGE business, la 780 de lei, pachet 2 MONOCROM",
    EXTRA_LARGE_3_MESSAGE: "Doresc pachetul EXTRA LARGE business, la 780 de lei, pachet 3 COLOR",
    CORPORATE_1_MESSAGE: "Doresc pachetul CORPORATE business, la 1190 de lei, pachet 1 MONOCROM",
    CORPORATE_2_MESSAGE: "Doresc pachetul CORPORATE business, la 1190 de lei, pachet 2 MONOCROM",
    CORPORATE_3_MESSAGE: "Doresc pachetul CORPORATE business, la 1190 de lei, pachet 3 COLOR",
    CORPORATE_4_MESSAGE: "Doresc pachetul CORPORATE business, la 1190 de lei, pachet 4 COLOR",
    CORPORATE_5_MESSAGE: "Doresc pachetul CORPORATE business, la 1190 de lei, pachet 5 COLOR",
    EPSON_1_MESSAGE: "Doresc pachetul Epson Work Force Pro WF-C579RDTWF la 30 euro + TVA",
    EPSON_2_MESSAGE: "Doresc pachetul Epson Work Force Pro WF-C878RDTWF la 55 euro + TVA",
    EPSON_3_MESSAGE: "Doresc pachetul Epson Workforce Enterprise WF-C20750 la 135 euro + TVA",
    BROTHER_MESSAGE: "Doresc pachetul Multifunctional A4 Monocrom Brother MFC-L6900DW la 27 euro + TVA",
    BROTHER_CUSTOM: "Doresc sa fiu contactat pentru o oferta personalizata",
    CONSULTANTA_CUSTOM: "Doresc sa fiu contactat de un consultant pentru a imi prezenta oferta.",

};

export const handleSubmit = async (event) => {
    event.preventDefault();

    const spinner = $('#spinner-contact');

    if (spinner !== undefined && spinner.length > 0) {
    $(spinner[0]).removeClass('d-none');
    }

    let ans = emailjs.sendForm(
      'service_ptc2etr',
      'template_hrv5wa7',
      event.target,
      'user_ojxxcQzaxVvNok36hxj34'
    ).then(response => {
        if (spinner !== undefined && spinner.length > 0) {
            $(spinner[0]).addClass('d-none');
        }

        if (response !== undefined && response.status === 200) {
            return 'success';
        }

        return 'error';

    }).catch(err => {
        if (spinner !== undefined && spinner.length > 0) {
            $(spinner[0]).addClass('d-none');
        }

        return 'error';
    });

    event.target.reset();

    return ans;
};
