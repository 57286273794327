import React from 'react';
import { NavLink } from 'react-router-dom';

const Header = () => {
    return (
        <header id="header" className={'d-flex align-items-center'}>
            <div className={'container d-flex align-items-center justify-content-between'}>

                <h2 className={'logo'}><NavLink to='/'>Printech</NavLink></h2>
                <nav id={'navbar'} className={'navbar'}>
                    <ul>
                        <li><NavLink className={'nav-link scrollto home'} to='/'>Acasă</NavLink></li>
                        <li><NavLink className={'nav-link scrollto'} to='/#about'>Despre Noi</NavLink></li>
                        <li><NavLink className={'nav-link scrollto'} to='/#services'>Servicii</NavLink></li>
                        <li><a className={'nav-link'} href='https://www.roink.ro/'>Magazin Online</a></li>
                        <li><NavLink className={'nav-link consultanta scrollto'} to='/consultanta'>Consultanta</NavLink></li>
                        <li className={'dropdown'}><a className={'btn nav-link'} style={{'cursor': 'pointer'}}><span>Închiriere Echipamente</span> <i className={'bi bi-chevron-down'}></i></a>
                            <ul>
                                <li><NavLink className={'scrollto pricing drop-a'} to='/#pricing'>Închiriere Copiatoare</NavLink></li>
                                <li><NavLink className={'scrollto brother drop-a'} to='/brother'>Brother</NavLink></li>
                                <li><NavLink className={'scrollto epson drop-a'} to='/epson'>Epson</NavLink></li>
                            </ul>
                        </li>
                        <li><NavLink className={'nav-link scrollto'} to='/#contact'>Contact</NavLink></li>
                    </ul>
                    <i className={'bi bi-list mobile-nav-toggle'}></i>
                </nav>
            </div>
        </header>
    );
}

export default Header;