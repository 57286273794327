import React, { useEffect, useState } from "react";
import PlanBanner from "./components/banner";
import { Modal, Button } from "react-bootstrap";
import ContactForm from '../contactForm';
import { constants, handleSubmit } from '../../utils';
import { useToasts } from 'react-toast-notifications';

const MediumBusiness = () => {
    const { addToast } = useToasts();
    const [pageProps, setPageProps] = useState({show: false, message: ''});
    const handleClose = () => setPageProps({show: false, message: pageProps.message});
    const handleShow = (msg) => {setPageProps({show: true, message: msg})};
    const handleSubmission = async (event) => {
      await handleSubmit(event).then(response => {
        if (response === 'success') {
            addToast('Email trimis cu succes!', {
                appearance: 'success',
                autoDismiss: true,
            });
        } else {
          addToast('Email-ul nu a putut fi trimis. Vă rugăm sa ne contactați telefonic!', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      });
    }

    useEffect(() => {
      let homeButtons = document.querySelectorAll('#navbar .scrollto');

      homeButtons.forEach((el) => {
        if (el.classList.contains('pricing')) {
          el.classList.add('active');
        } else {
          el.classList.remove('active');
        }
      });
    });

    return (
    <section className={'pricing'}>
      <div className={'container'} data-aos="fade-up">

        <div className={'section-title'}>
          <h2>Preturi</h2>
          <p>Toate Pachetele au Consumabile, Service, Transport, Instalare, Instruire personal INCLUSE și timp de intervenție GARANTAT.</p>
        </div>

        <div className={'row my-4 justify-content-center'}>

          <div className={'col-lg-4'} data-aos="fade-up" data-aos-delay="100">
            <div className={'box'}>
              <h3>Pachet 1 MONOCROM</h3>
              <h4><sup>lei</sup>350<span>lună</span></h4>
              <ul>
                <li><span>5000 pagini monocrom incluse</span> </li>
                <li>Copiator laser A3 - 1 buc</li>
                <li className={'na'}>Pagini color incluse</li>
                <li className={'na'}>Imprimantă laser</li>
              </ul>
              <div className={'btn-wrap'}>
                <Button onClick={(e) => handleShow(constants.MEDIUM_1_MESSAGE)} className={'btn-buy'}>Cere ofertă</Button>
              </div>
            </div>
          </div>

          <div className={'col-lg-4'} data-aos="fade-up" data-aos-delay="200">
            <div className={'box'}>
              <h3>Pachet 2 MONOCROM</h3>
              <h4><sup>lei</sup>350<span>lună</span></h4>
              <ul>
                <li><span>5000 pagini monocrom incluse</span></li>
                <li>Imprimantă laser A4 - 1 buc</li>
                <li>Multifuncțional A4 - 1 buc</li>
                <li className={'na'}>Pagini color incluse</li>
                <li className={'na'}>Copiator laser</li>
              </ul>
              <div className={'btn-wrap'}>
                <Button onClick={(e) => handleShow(constants.MEDIUM_2_MESSAGE)} className={'btn-buy'}>Cere ofertă</Button>
              </div>
            </div>
          </div>
          
          <div className={'col-lg-4'} data-aos="fade-up" data-aos-delay="200">
            <div className={'box'}>
              <h3>Pachet 3 COLOR</h3>
              <h4><sup>lei</sup>350<span>lună</span></h4>
              <ul>
                <li><span>1000 pagini monocrom incluse</span> </li>
                <li ><span>200 pagini color incluse</span> </li>
                <li>Multifuncțional A3/A4 - 1 buc</li>
                <li className={'na'}>Imprimantă laser</li>
                <li className={'na'}>Copiator laser</li>
              </ul>
              <div className={'btn-wrap'}>
                <Button onClick={(e) => handleShow(constants.MEDIUM_3_MESSAGE)} className={'btn-buy'}>Cere ofertă</Button>
              </div>
            </div>
          </div>
          </div>
        </div>

        <PlanBanner />

        <Modal show={pageProps.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Cerere oferta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ContactForm handleSubmit={handleSubmission} message={pageProps.message}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
    </section>
    );
};

export default MediumBusiness;