import React, { useEffect } from 'react';
import EpsonServices from '../epson/components/services';
import BrotherAdvantages from './sections/advantages';
import OrderBrother from './sections/orderBrother';
import logo from '../../../img/brother/logo.svg';
import WelcomeBanner from './sections/welcome-banner-brother'

const Brother = (props) => {
    useEffect(() => {
        let homeButtons = document.querySelectorAll('#navbar .scrollto');

        homeButtons.forEach((el) => {
            if (el.classList.contains('brother')) {
                el.classList.add('active');
            } else {
                el.classList.remove('active');
            }
        });
    });

    return (
        <>
            
            <WelcomeBanner />
            <EpsonServices />
            <OrderBrother />

            <div className={'text-center mb-4'}>
                <h2 className="mx-auto">BENEFICIILE BROTHER MPS</h2>
            </div>

            <BrotherAdvantages />

            <div className={'container'}>
                <br/>
                <p>
                    Hartia nu este inclusă în costurile contractului. Ea este asigurată de Custode (Depozitar).
                </p>
                <br/>
                <p>Condiții comerciale:</p>
                <br/>
                <ol>
                    <li>
                        Prețurile sunt exprimate în Euro și nu conțin TVA;
                    </li>
                    <li>
                    Livrarea se face pe baza unei comenzi ferme a produselor, în aproximativ 3 zile.
                    </li>
                </ol>
                <br/>
                <p> Pentru o ofertă personalizată, vă rugăm să ne contactați!</p>
            </div>
        </>
    );
};

export default Brother;