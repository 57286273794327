import React, { useEffect } from 'react';
import OrderEpson from './components/order';
import EpsonServices from './components/services';
import SlideShowEpson from './components/slideshow';
import BusinessPlanEpson from './sections/businessPlan';
import TableSectionEpson from './sections/tableSection';
import Brochure from '../../../files/brosura.pdf';
import logo from '../../../img/epson/logo.png'

const Epson = (props) => {
    useEffect(() => {
        let homeButtons = document.querySelectorAll('#navbar .scrollto');

        homeButtons.forEach((el) => {
            if (el.classList.contains('epson')) {
                el.classList.add('active');
            } else {
                el.classList.remove('active');
            }
        });
    });

    return (
        <>
            <div className={'container'}>
                <div className={'text-center mt-4'}>
                    <img src={logo} alt="logo"/>
                </div>
            </div>

            <EpsonServices />
            <OrderEpson />

            <div className={'text-center mb-4'}>
                <h2 className="mx-auto">SOLUȚII DE IMPRIMARE PENTRU COMPANII</h2>
            </div>

            <SlideShowEpson />
            <TableSectionEpson />
            <BusinessPlanEpson />

            <div className={'container'}>
                <br/>
                <p>
                    Hartia nu este inclusă în costurile contractului. Ea este asigurată de Custode (Depozitar).
                </p>
                <br/>
                <p>Condiții comerciale:</p>
                <br/>
                <ol>
                    <li>
                        Prețurile sunt exprimate în Euro și nu conțin TVA;
                    </li>
                    <li>
                    Livrarea se face pe baza unei comenzi ferme a produselor, în aproximativ 3 zile.
                    </li>
                </ol>
                <br/>
                <p> Pentru o ofertă personalizată vă rugăm să ne contactați.</p>
                <a href={Brochure} className={'btn btn-primary my-3'} target="_blank" rel="noreferrer" download>Descarcă brosura</a>
            </div>
        </>
    );
};

export default Epson;