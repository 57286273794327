import React, { useEffect } from 'react';
import ReactState, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ContactForm from '../../contactForm';
import { constants, handleSubmit } from '../../../utils';
import { useToasts } from 'react-toast-notifications';

const WelcomeBanner = () => {
    const { addToast } = useToasts();
    const [pageProps, setPageProps] = useState({show: false, message: ''});
    const handleClose = () => setPageProps({show: false, message: pageProps.message});
    const handleShow = (msg) => {setPageProps({show: true, message: msg})};

    const handleSubmission = async (event) => {
        await handleSubmit(event).then(response => {
          if (response === 'success') {
              addToast('Email trimis cu succes!', {
                  appearance: 'success',
                  autoDismiss: true,
              });
          } else {
            addToast('Email-ul nu a putut fi trimis. Va rugam sa ne contactati telefonic!', {
              appearance: 'error',
              autoDismiss: true,
            });
          }
        });
    }

    useEffect(() => {
        let homeButtons = document.querySelectorAll('#navbar .scrollto');
  
        homeButtons.forEach((el) => {
          if (el.classList.contains('home')) {
              if (!el.classList.contains('active')) {
                el.classList.add('active');
              }
          } else {
            el.classList.remove('active');
          }
        });
    });

    return (
        <section id="hero-brother" className={'consult d-flex align-items-center'}>
            <div id="brother-text" className={'container d-flex flex-column'} data-aos="zoom-out" data-aos-delay="100">
                <h1 className={'mx-auto'}>Consultanță <span>IT</span></h1>
                <h2 className={'mx-auto'}> Profita la maxim de finantarea pe Fonduri Europene sau Fonduri Guvernamentale pe care doresti sa o accesezi prin achizitionarea echipamentelor IT ce vor propulsa cat mai mult afacerea ta.</h2>
                <div className={'pricing mx-auto'}>
                   <Button onClick={(e) => handleShow(constants.CONSULTANTA_CUSTOM)} className={'offer btn-buy'}>Incepe o discutie!</Button>
                </div>
            </div>

            <Modal show={pageProps.show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Cerere oferta</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ContactForm handleSubmit={handleSubmission} message={pageProps.message}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
            </Modal>
        </section>
    );
}

export default WelcomeBanner;