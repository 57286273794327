import React from 'react';

const Services = () => {
    return (
        <section id="featured-services" className={'featured-services'}>
          <div className={'container'} data-aos="fade-up">
            <div className={'row justify-content-center'}>
              <div className={'col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0'}>
                <div className={'icon-box'} data-aos="fade-up" data-aos-delay="100">
                  <div className={'icon'}><i className={'bx bx-cog'}></i></div>
                  <h4 className={'title'}><a href="#services">Service IT</a></h4>
                  <p className={'description'}>Orice problemă, o singură soluție. Adu-ne dispozitivul tău stricat și va fi reparat numaidecât!</p>
                </div>
              </div>
    
              <div className={'col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0'}>
                <div className={'icon-box'} data-aos="fade-up" data-aos-delay="200">
                  <div className={'icon'}><i className={'bx bx-shopping-bag'}></i></div>
                  <h4 className={'title'}><a href="https://www.roink.ro/">Magazin Online</a></h4>
                  <p className={'description'}>Pe magazinul nostru găsești tot ce ai nevoie. De la piese, la tonere și chiar imprimante multifuncționale gata de treaba.</p>
                </div>
              </div>
    
              <div className={'col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0'}>
                <div className={'icon-box'} data-aos="fade-up" data-aos-delay="300">
                  <div className={'icon'}><i className={'bx bx-printer'}></i></div>
                  <h4 className={'title'}><a href="#pricing">Închiriere copiatoare</a></h4>
                  <p className={'description'}>Alege soluția inteligentă! Închiriază un copiator de la Printech Company!</p>
                </div>
              </div>
            </div>
          </div>
        </section>
    );
}

export default Services;