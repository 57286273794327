import React from 'react';

const TopBar = () => {
    return (
        <section id="topbar" className={'d-flex align-items-center'}>
            <div className={'container d-flex justify-content-center justify-content-md-between'}>
                <div className={'contact-info d-flex align-items-center'}>
                    <i className={'bi bi-envelope d-flex align-items-center'}><a href="mailto:office@printechcompany.ro">office@printechcompany.ro</a></i>
                    <i className={'bi bi-phone d-flex align-items-center ms-4'}><span>0353416464</span></i>
                </div>
                <div className={'social-links d-none d-md-flex align-items-center'}>
                    <a href="https://www.facebook.com/PrintechCompany" className={'facebook'}><i className={'bi bi-facebook'}></i></a>
                    <a href="https://www.instagram.com/printechcompany/" className={'instagram'}><i className={'bi bi-instagram'}></i></a>
                </div>
            </div>
        </section>
    );
};

export default TopBar;