import React, { forwardRef, useImperativeHandle, useState } from 'react';

const CommonForm = forwardRef((props, ref) => {
    const [message, setMessage] = useState('');

    useImperativeHandle(
        ref,
        () => ({
            composeMessage() {
                return {'message' : 'Mesaj: ' + message + '<br/'};
            }
        }),
    );

    return (
        <>
            <div className={'form-group'}>
                <textarea onChange={(e) => setMessage(e.target.value)} className={'form-control'} name="message" rows="5" placeholder="Mesaj" required></textarea>
            </div>
        </>
    ); 
});

export default CommonForm;