import React, { useEffect, useState } from "react";
import CountUp from 'react-countup';
import $ from 'jquery';

const Statictics = () => {
    const [show, setShow] = useState(false);
    const callBackListener = (event) => {
        if (window.scrollY + 800 >= $('#counts').offset().top &&
            show === false) {
            setShow(true);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', callBackListener, [show]);

        return () => {
            window.removeEventListener('scroll', callBackListener);
        }
    });

    return(
        <section id="counts" className={'counts'}>
            <div className={'container'} data-aos="fade-up">
                <div className={'row'}>
                    <div className={'col-lg-3 col-md-6'}>
                        <div className={'count-box'}>
                            <i className={'bi bi-emoji-smile'}></i>
                            <CountUp redraw="true" end={5379} duration={2}/>
                            <p>Clienți fericiți</p>
                        </div>
                    </div>

                    <div className={'col-lg-3 col-md-6 mt-5 mt-md-0'}>
                        <div className={'count-box'}>
                            <i className={'bi bi-alarm'}></i>
                            <CountUp redraw="true" end={17} duration={2}/>
                            <p>Ani de experiență</p>
                        </div>
                    </div>

                    <div className={'col-lg-3 col-md-6 mt-5 mt-lg-0'}>
                        <div className={'count-box'}>
                            <i className={'bi bi-headset'}></i>
                            <CountUp redraw="true" end={18587} duration={2}/>
                            <p>Ore de suport</p>
                        </div>
                    </div>

                    <div className={'col-lg-3 col-md-6 mt-5 mt-lg-0'}>
                        <div className={'count-box'}>
                            <i className={'bi bi-people'}></i>
                            <CountUp redraw="true" end={11} duration={2}/>
                            <p>Angajați</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Statictics;