import React from "react";
import home from '../../../../img/epson/banners/home.jpg';
import office from '../../../../img/epson/banners/office.jpg';
import smb from '../../../../img/epson/banners/SMB.jpg';

const BusinessPlanEpson = (props) => {
    return (
        <>
            <div className={'container'}>
                <div className={'row align-items-center my-4'}>
                    <div className={'col-md-6 float-left'}>
                        <img src={office} className={'img-fluid'} alt='Office Environment'/>
                    </div>

                    <div className={'col-md-6 float-left'}>
                        <h3>Imprimante business pentru companii</h3>

                        <p>
                            Concepută pentru afaceri, gama Epson de imprimante business cu jet de cerneală performanțe îmbunătățește productivitatea generală fără a face sacrificii în ceea ce privește calitatea. Imprimarea de mare viteză vă permite să imprimați rapid și ușor volume mari de documente, iar tehnologia inovatoare simplifică fluxurile de lucru pentru a asigura un plus de eficiență și de interactivitate la locul dumneavostră de muncă.
                        </p>
                    </div>
                </div>

                <div className={'row align-items-center my-4 reversed-order'}>
                    <div className={'col-md-6'}>
                        <img src={smb} className={'img-fluid'} alt='Small Business Environment'/>
                    </div>

                    <div className={'col-md-6'}>
                        <h3>Imprimante pentru sectorul SMB/SME</h3>

                        <p>
                            Sporiți productivitatea și reduceți costurile de exploatare la minimum cu ajutorul tehnologiei rapide și puternice, proiectate pentru a satisface cerințele de afaceri. Gama Epson de imprimante business cu jet de cerneală WorkForce cu numeroase funcții este excelentă pentru birourile cu așteptări ridicate în ceea ce privește volumul și calitatea de imprimare. Fiabile, rapide și durabile, imprimantele cu jet de cerneală oferă calitate, viteză și valoare oricărui birou de dimensiuni mici.
                        </p>
                    </div>
                </div>

                <div className={'row align-items-center my-4'}>
                    <div className={'col-md-6 float-left'}>
                        <img src={home} className={'img-fluid'} alt='Home Environment'/>
                    </div>

                    <div className={'col-md-6 float-left'}>
                        <h3>Imprimante pentru birouri mici și birourile de acasă</h3>

                        <p>
                            Epson oferă soluții ideale pentru birourile mici și de acasă aglomerate, cu o imprimare fárà probleme și de calitate superioarà, la costuri forțe scäzute. Îmbunătățiți eficiență echipei dvs. datorită operării fiabile și rezultatelor de calitate, care vă ajută să
                            economisiți bani, timp și să reduceți problemele inutile pentru a contribui la dezvoltarea firmei dus.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BusinessPlanEpson;