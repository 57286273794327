import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import epson1 from '../../../../img/epson/slideshow/poza1.png';
import epson2 from '../../../../img/epson/slideshow/poza2.png';
import epson3 from '../../../../img/epson/slideshow/poza3.png';

const SlideShowEpson = (props) => {
    return (
        <div className={'container'}>
            <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={epson1}
                        alt="First slide"
                    />
                    <Carousel.Caption>
                        <Button><a href="https://www.epson.ro/products/printers/inkjet-printers/business-inkjet" className={'details-button'}>Click pentru detalii</a></Button>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={epson2}
                        alt="Second slide"
                    />

                    <Carousel.Caption>
                        <Button><a href="https://www.epson.ro/products/printers/inkjet-printers/business-inkjet" className={'details-button'}>Click pentru detalii</a></Button>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={epson3}
                        alt="Third slide"
                    />

                    <Carousel.Caption>
                        <Button><a href="https://www.epson.ro/products/printers/inkjet-printers/business-inkjet" className={'details-button'}>Click pentru detalii</a></Button>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </div>
    );
};

export default SlideShowEpson;