import React, { forwardRef, useImperativeHandle, useState } from 'react';

const PcForm = forwardRef((props, ref) => {
    const [config, setConfig] = useState('');
    const [problem, setProblem] = useState('');

    useImperativeHandle(
        ref,
        () => ({
            composeMessage() {
                return {'message' : 'Configuratie PC: ' + config + '<br/Descriere defect: ' + problem +  '<br/'};
            }
        }),
    );

    return (
        <>
            <div className={'form-group'}>
                <textarea onChange={(e) => setConfig(e.target.value)} className={'form-control'} name="config" rows="5" placeholder="Configuratie" required></textarea>
            </div>

            <div className={'form-group'}>
                <textarea onChange={(e) => setProblem(e.target.value)} className={'form-control'} name="defect" rows="5" placeholder="Descriere defect" required></textarea>
            </div>
        </>
    ); 
});

export default PcForm;