import React from 'react';

const EpsonServices = (props) => {
    return (
        <section id="featured-services" className={'featured-services'}>
            <div className={'container'} data-aos="fade-up">
                <div className={'row justify-content-center'}>
                <div className={'col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0'}>
                    <div className={'icon-box'} data-aos="fade-up" data-aos-delay="100">
                    <div className={'icon'}><i className={'bx bx-check-square'}></i></div>
                    <p className={'description'}>În cazul în care aveți nevoie să achiziționați o imprimantă, societatea noastră vă oferă acest echipament spre folosință la prețuri competitive. Toate costurile de întreținere (SERVICE, PIESE, MANOPERĂ, INSTALARE, INSTRUIRE și TRANSPORT) a echipamentelor date spre folosință vor fi în sarcina noastră.</p>
                    </div>
                </div>

                <div className={'col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0'}>
                    <div className={'icon-box'} data-aos="fade-up" data-aos-delay="200">
                    <div className={'icon'}><i className={'bx bx-tachometer'}></i></div>
                    <p className={'description'}>Calitatea reprezintă o parte importantă a produselor și serviciilor PRINTECH COMPANY, de aceea oferim numai cartușe originale noi, astfel încât echipamentele să printeze la calitate maximă.</p>
                    </div>
                </div>

                <div className={'col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0'}>
                    <div className={'icon-box'} data-aos="fade-up" data-aos-delay="300">
                    <div className={'icon'}><i className={'bx bx-file'}></i></div>
                    <p className={'description'}>Service-ul de mentenanță și cel de intervenție sunt asigurate în cadrul contractului de închiriere, fără costuri suplimentare. De asemenea, dacă reparația echipamentului durează mai mult de 48 de ore, vă punem la dispoziție echipament de back-up.</p>
                    </div>
                </div>
                </div>
            </div>
        </section>
    );
}

export default EpsonServices;