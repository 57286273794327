import React from "react";

const Faq = () => {
    return (
    <section id="faq" className={'faq section-bg'}>
      <div className={'container'} data-aos="fade-up">

        <div className={'section-title'}>
          <h2>F.A.Q</h2>
          <h3><span>Întrebări</span> Frecvente</h3>
          {/* <p>Ut possimus qui ut temporibus culpa velit eveniet modi omnis est adipisci expedita at voluptas atque vitae autem.</p> */}
        </div>

        <div className={'row justify-content-center'}>
          <div className={'col-xl-10'}>
            <ul className={'faq-list'}>
              <li>
                <div data-bs-toggle="collapse" className={'collapsed question'} href="#faq1">Aveți un produs defect. Cum procedați? <i className={'bi bi-chevron-down icon-show'}></i><i className={'bi bi-chevron-up icon-close'}></i></div>
                <div id="faq1" className={'collapse'} data-bs-parent=".faq-list">
                  <p>
                    Dacă produsul dumneavoastră s-a defectat, vă oferim urmatoarele soluții: <br/>
                    - Îl puteți aduce la service-ul Printech Company: strada 14 Octombrie, nr. 46, Târgu-Jiu, Gorj (la 50m de Piața Mare, lângă Hotel Anna Junior)<br/><br/>
                    - Aveți posibilitatea de a completa formularul de service aflat pe prima pagină a site-ului. După înregistrarea solicitării și aprobarea ei de către un operator, veți primi un apel din partea Printech Company în vederea programării în service și ridicării produsului.
                  </p>
                </div>
              </li>

              <li>
                <div data-bs-toggle="collapse" href="#faq2" className={'collapsed question'}>În cât timp aflu ce defect are echipamentul? <i className={'bi bi-chevron-down icon-show'}></i><i className={'bi bi-chevron-up icon-close'}></i></div>
                <div id="faq2" className={'collapse'} data-bs-parent=".faq-list">
                  <p>
                  Pentru calculatoare, dacă diagnosticul nu se poate oferi pe loc, acesta se va da în max 24 de ore, iar pentru laptop-uri, în cel mult 48 de ore. Pentru imprimante și copiatoare termenul este de 24 de ore.
                  </p>
                </div>
              </li>

              <li>
                <div data-bs-toggle="collapse" href="#faq3" className={'collapsed question'}>Cât durează reparația unui produs? <i className={'bi bi-chevron-down icon-show'}></i><i className={'bi bi-chevron-up icon-close'}></i></div>
                <div id="faq3" className={'collapse'} data-bs-parent=".faq-list">
                  <p>
                  Termenul de reparație poate fi între 1-15 zile calendaristice, în funcție de disponibilitatea pieselor de schimb la furnizor.
                  </p>
                </div>
              </li>

              <li>
                <div data-bs-toggle="collapse" href="#faq4" className={'collapsed question'}>Produsul meu se află în perioada de garanție. Ce acte sunt necesare pentru reparație? <i className={'bi bi-chevron-down icon-show'}></i><i className={'bi bi-chevron-up icon-close'}></i></div>
                <div id="faq4" className={'collapse'} data-bs-parent=".faq-list">
                  <p>
                  Actele necesare reparării produsului în garanție sunt următoarele:<br/>
                  • Copie factură fiscală, bon fiscal sau copie contract;<br/>
                  • Certificatul de garanție (în original).<br/>
                  </p>
                </div>
              </li>

              <li>
                <div data-bs-toggle="collapse" href="#faq5" className={'collapsed question'}>Cum trebuie ambalat produsul? <i className={'bi bi-chevron-down icon-show'}></i><i className={'bi bi-chevron-up icon-close'}></i></div>
                <div id="faq5" className={'collapse'} data-bs-parent=".faq-list">
                  <p>
                  În cazul transportului cu firma de curierat, vă recomandăm ambalarea produsului în cutia originală. Dacă nu există această posibilitate, puteți utiliza un alt ambalaj. Important este ca produsul să fie ambalat corespunzător și să aibă materiale de protecție, pentru a fi în siguranță pe perioada transportului.
                  </p>
                </div>
              </li>

              <li>
                <div data-bs-toggle="collapse" href="#faq6" className={'collapsed question'}>Ce garanție am la reparație? <i className={'bi bi-chevron-down icon-show'}></i><i className={'bi bi-chevron-up icon-close'}></i></div>
                <div id="faq6" className={'collapse'} data-bs-parent=".faq-list">
                  <p>
                  Garanția la reparație sau la piesele înlocuite se acordă astfel:<br/>
                  - Garanție manoperă (unde nu s-au înlocuit piese) - 3 luni.<br/>
                  - Garanție piese - între 6 - 99 luni (în funcție de producător și starea produsului).<br/>
                  </p>
                </div>
              </li>

            </ul>
          </div>
        </div>

      </div>
    </section>
    );
};

export default Faq;