import React from "react";
import { Table } from "react-bootstrap";

const TableSectionEpson = (props) => {
    return (
        <div className={'container my-5'}>
            <Table responsive="sm">
                <thead>
                <tr style={{textAlign: 'center'}}>
                    <th>#</th>
                    <th>Model</th>
                    <th>Utilizatori pentru fiecare dispozitiv</th>
                    <th>Viteza</th>
                    <th>Cost pe pagina</th>
                    <th>Investitie initiala</th>
                    <th>Volum de imprimare lunar</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>1</td>
                    <td>
                        WorkForce Enterprise<br/>
                        Imprimanta business cu jet de cerneală WorkForce Enterprise combină performanța ultrarapidă cu un consum redus de energie.
                    </td>
                    <td>20+</td>
                    <td>Cel mai ridicat</td>
                    <td>Foarte scăzut (contract MPS)</td>
                    <td>Contract MPS</td>
                    <td>8.000 - 100.000</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>
                        WorkForce Pro RIPS<br/>
                        Eliminați frustrările tradiționale legate de imprimante și economisiți timp pentru afacerea dumneavostră prin intermediul celor mai noi imprimante business WorkForce Pro RIPS.
                    </td>
                    <td>6 - 15</td>
                    <td>Ridicat</td>
                    <td>Foarte scăzut (contract MPS)</td>
                    <td>Contract MPS</td>
                    <td>1.500 - 10.000</td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>
                        WorkForce Pro<br/>
                        Proiectate pentru afaceri, imprimantele business cu jet de cerneală WorkForce Pro asigură o fiabilitate exceptională, un impact redus asupra mediului înconjurator și reducerea deșeurilor.
                    </td>
                    <td>1 - 10</td>
                    <td>Ridicat</td>
                    <td>Scăzut</td>
                    <td>Mediu</td>
                    <td>200 - 4.000</td>
                </tr>
                <tr>
                    <td>4</td>
                    <td>
                        WorkForce<br/>
                        Gama de dimensiuni mici, dar foarte puternică de imprimante business cu jet de cerneală WorkForce oferă calitate, viteză și valoare pentru birourile mici.
                    </td>
                    <td>1 - 4</td>
                    <td>Mediu</td>
                    <td>Mediu</td>
                    <td>Scăzut</td>
                    <td>50 - 200</td>
                </tr>
                <tr>
                    <td>5</td>
                    <td>
                        EcoTank<br/>
                        Bucurați-vă de imprimarea fară cartușe și la cost minim cu seria EcoTank.
                    </td>
                    <td>1 - 4</td>
                    <td>Scăzut</td>
                    <td>Extrem de scăzut</td>
                    <td>Ridicat</td>
                    <td>100 - 400</td>
                </tr>
                </tbody>
            </Table>
            </div>
    );
};

export default TableSectionEpson;