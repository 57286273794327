import React, { useEffect } from 'react';

const WelcomeBanner = () => {

    useEffect(() => {
        let homeButtons = document.querySelectorAll('#navbar .scrollto');
  
        homeButtons.forEach((el) => {
          if (el.classList.contains('home')) {
              if (!el.classList.contains('active')) {
                el.classList.add('active');
              }
          } else {
            el.classList.remove('active');
          }
        });
    });

    return (
        <section id="hero" className={'d-flex align-items-center'}>
            <div className={'container'} data-aos="zoom-out" data-aos-delay="100">
                <h1><span>Printech</span> - servicii IT premium</h1>
                <h2>Alege soluția inteligentă pentru business-ul tău alături de Printech</h2>
                <a href="#about" className={'btn-get-started scrollto'}>Vezi Servicii</a>
                <br></br>
                <h5 className='info-text first-info-text'>Ne face plăcere să vă informăm că PRINTECH se va muta într-un nou sediu.</h5>
                <h5 className='info-text'>Amplasat la numai 150 m de sediul cel vechi, ne găsiți începând cu 11 ianuarie în str. 14 octombrie, nr. 64, Târgu Jiu.</h5>
                <h5 className='info-text'>Această schimbare vine cu multiple oportunități, un spațiu nou de lucru modern care respectă toate standardele, atmosferă <br></br> primitoare care ne vor permite o colaborare mai bună și o eficiență sporită.</h5>
                <h5 className='info-text'>Vă așteptăm în sediul nou să achizițonați produsele și serviciile noastre!</h5>
                <div className={'d-flex'}>
                </div>
            </div>
        </section>
    );
}

export default WelcomeBanner;