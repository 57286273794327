import React, { useEffect } from 'react';


const CookiesPolicy = (props) => {
    useEffect(() => {
        let homeButtons = document.querySelectorAll('#navbar .scrollto');

        homeButtons.forEach((el) => {
            el.classList.remove('active');
        });
    });

    return (
        <div className={'container'}>
            <p>Prezenta Politică privind fișierele cookies se aplică tuturor utilizatorilor paginii de internet www.printechcompany.ro. Informațiile prezentate în continuare au ca scop informarea utilizatorilor cu privire la plasarea, utilizarea și administrarea cookie-urilor în contextul navigării pe această pagină de internet.</p>
            <p><strong>1. Ce este un cookie?</strong></p>
            <p>Un cookie este un fisier text de mici dimensiuni, format in general din identificatori, nume ale website-urilor litere si numere, caruia utilizatorul, cand navigheaza pe pagina web prin intermediul unui browser web (ex: Internet Explorer, Chrome, Firefox etc.), ii poate permite sau nu instalarea prin stocare pe computerul, terminalul mobil sau alte echipamente folosite de acesta. Fisierul cookie este “pasiv” (nu contine programe software, virusi sau spyware si nu poate accesa informatiile de pe hard disk-ul utilizatorului).</p>
            <p><strong>Cookie-urile se clasifică în:</strong><br/> – Cookie-uri de sesiune – acestea sunt stocate temporar în istoricul browser-ului care le memorează până la momentul în care utilizatorul iese de pe website-ul respectiv sau închide fereastra browserului.</p>
            <p>– Cookie-uri persistente – acestea sunt stocate, în funcție de durata prestabilită, pe hard-drive-ul unui computer sau echipament. Aceste cookie-uri le includ și pe cele plasate de un alt website decât cel pe care îl vizitează utilizatorul la momentul respectiv si sunt cunoscute sub numele de “third party cookies” – care pot fi folosite în mod anonim pentru a memora interesele unui utilizator, astfel încât să fie livrată publicitate cât mai relevantă pentru utilizatori.</p>
            <p><strong>2. La ce sunt folosite cookie-urile?</strong></p>
            <p>Aceste fisiere fac posibila recunoasterea terminalului utilizatorului si prezentarea catre acesta a unui continut adaptat preferintelor sale. Cookies asigura utilizatoriului o experienta placuta de navigare si sustin eforturile www.printechcompany.ro pentru a oferi servicii confortabile utilizatorului, cum ar fi: preferintele in materie de confidentialitate online, cosul de cumparaturi sau publicitate relevanta. Cookies sunt utilizate in pregatirea unor statistici anonime agregate care ne ajuta sa intelegem modul in care un utilizator beneficiaza de paginile noastre web, permitandu-ne imbunatatirea structurii si continutului acestora.</p>
            <p><strong>3. Ce tipuri de Cookie-uri folosim?</strong></p>
            <p>O vizită pe acest site poate plasa cookie-uri în scopuri de:</p>
            <p><strong>Cookie-uri de performanță</strong><br/> Acest tip de cookie reține preferințele utilizatorului pe acest site, așa încât nu mai este nevoie de setarea lor la fiecare vizitare a site-ului.<br/> Spre exemplu: setările volumului pentru video player, viteza de video streaming cu care este compatibil browserul.</p>
            <p><strong>Cookie-uri pentru analiza vizitatorilor</strong><br/> De fiecare dată când un utilizator vizitează acest site, softul de analytics furnizat de o terță parte generează un cookie de analiză a utilizatorului. Acest cookie ne spune dacă ai mai vizitat acest site până acum. Browserul ne va spune dacă ai acest cookie, iar dacă nu, vom genera unul. Acesta permite monitorizarea utilizatorilor unici care ne vizitează și cât de des o fac. Atât timp cât nu ești înregistrat pe acest site, acest cookie nu poate fi folosit pentru a identifica persoanele fizice, ele sunt folosite doar în scop statistic. Dacă ești înregistrat putem ști, de asemenea, detaliile pe care ni le-ai furnizat, cum ar fi adresa de e-mail și username-ul – acestea fiind supuse confidențialității și prevederilor din Termeni și Condiții, Politica de confidențialitate precum și prevederilor legislației în vigoare cu privire la protejarea datelor cu caracter personal.</p>
            <p><strong>Cookie-uri pentru geotargeting</strong><br/> Aceste cookie-uri sunt utilizate de către un soft care stabilește din ce țară provii. Este complet anonim și este folosit doar pentru a targeta conținutul – chiar și atunci când ești pe pagina noastră în limba romana sau în alta limbă primiți aceeași reclamă.</p>
            <p><strong>Cookie-uri pentru publicitate</strong><br/> Aceste cookie-uri ne permit să aflăm dacă ai vizualizat sau nu o reclamă online, care este tipul acesteia și ce durată de timp a trecut de când ai văzut mesajul publicitar. Aceste cookie-uri se folosesc și pentru a targeta publicitatea online. Putem folosi, de asemenea, cookie-uri aparținând unei terțe părţi, pentru o mai bună targetare a publicității, pentru a arăta de exemplu reclame despre vacanțe, dacă utilizatorul a vizitat recent un articol pe site despre vacanțe. Aceste cookie-uri sunt anonime, ele stochează informații despre conținutul vizualizat, nu despre utilizatori. De asemenea, setam cookie-uri anonime și prin alte site-uri pe care avem publicitate. Primindu-le astfel, noi le putem folosi pentru a te recunoaște că vizitator al acelui site dacă ulterior vei vizita site-ul nostru și îți vom putea livra publicitatea bazată pe aceasta informație.</p>
            <p><strong>Alte cookie-uri ale terțelor părți</strong><br/> Pe unele pagini, terții pot seta propriile cookie-uri anonime, în scopul de a urmări succesul unei aplicații sau pentru a customiza o aplicație. Datorită modului de utilizare, acest site nu poate accesa aceste cookie-uri, la fel cum terțele părți nu pot accesa cookie-urile deținute de acest site. De exemplu, când distribui un articol folosind butonul pentru rețelele sociale aflat pe acest site, acea rețea sociala va înregistra activitatea ta.</p>
            <p><strong> 4. Ce tip de informații sunt stocate și accesate prin intermediul cookie-urilor? </strong></p>
            <p>Cookie-urile păstrează informații într-un fișier text de mici dimensiuni care permite unui website să recunoască un browser. Webserverul va recunoaște browserul până când cookie-ul expiră sau este șters. Cookie-ul stochează informații importante care îmbunătățesc experiența de navigare pe Internet (ex: setările limbii în care se dorește accesarea unui site; păstrarea unui user logat în contul de webmail; securitatea online banking; păstrarea produselor în coșul de cumpărături).</p>
            <p><strong>5. Cum puteţi controla fişierele de tip cookie</strong></p>
            <p>Dezactivarea şi refuzul de a primi cookie-uri îţi poate limita experienţa navigării pe internet, făcând anumite site-uri impracticabile sau dificil de vizitat. Însă alegerea este întotdeauna a utilizatorului.</p>
            <p>Vă puteți retrage consimțământul privind fișierele de tip cookie în orice moment. În acest scop, puteţi să blocaţi sau eliminaţi fişierele cookie fie prin intermediul setărilor browserului dvs. web sau prin utilizarea unor software-uri puse la dispoziţie de terți, sau urmând instrucţiunile specifice pentru categoriile de cookie din secţiunile următoare. In orice situaţie, pot apărea probleme legate de utilizarea anumitor părți ale site-ului web dacă dezactivaţi fişierele de tip cookie.</p>
            <p>În cazul în care doriți să eliminați fișierele de tip cookie stocate pe dispozitivele dvs. și să configurați browserul dvs. web astfel încât să refuze fișiere de tip cookie sau sa afiseze o notificare de avertizare inainte ca un cookie sa fie stocat pe calculatorul dumneavoastra, puteți să utilizați setările privind preferințele din browser-ul dvs. web. De obicei, puteți găsi setările de navigare referitoare la fișiere de tip cookie în meniurile „Opțiuni”, „Instrumente” sau „Preferințe” din browserul web. În funcție de browserele web existente, pot fi utilizate mijloace diverse pentru a dezactiva fișierele de tip cookie. Pentru a obține mai multe informații, vă rugăm să vizitați website-ul browserelor dvs. Daca nu cunoasteti tipul si versiunea de web browser pe care-l folositi, accesati meniul ‘Help’ in partea de sus a browserului, apoi ‘About’. Informatia relevanta va fi afisata.</p>
            <p>– Setări cookie în Internet Explorer – <a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11"> Internet Explorer </a> ;<br/> – Setări cookie în Firefox – <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"> Firefox </a> ;<br/> – Setări cookie în Chrome – <a href="https://support.google.com/chrome/answer/95647?hl=en&amp;hlrm=en"> Chrome </a> ;<br/> – Setări cookie în Safari – <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"> Safari </a> ;<br/> – Setări cookie în Edge – <a href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy"> Edge </a> ;<br/> – Setări cookie în Opera – <a href="https://www.opera.com/help/tutorials/security/privacy/">Opera</a></p>
            <p>Puteți refuza utilizarea cookie-urilor Google Analytics descărcând și instalând Google Analytics Opt-out Browser Add-on.</p>
            <ul type="disc">
            <li><a href="https://tools.google.com/dlpage/gaoptout?hl=en"> Google Analytics Opt-out Browser Add-on </a></li>
            </ul>
            <p>Pentru setarile cookie-urilor generate de terți și pentru mai multe informații privind confidențialitatea legată de publicitatea online, IAB România pune la dispoziție următorul site:<br/> <a href="http://www.youronlinechoices.com/ro/"> http://www.youronlinechoices.com/ro/ </a></p>
            <p><strong> 6. Ce tip de informații sunt stocate și accesate prin intermediul cookie-urilor? </strong></p>
            <p>Cookie-urile păstrează informații într-un fișier text de mici dimensiuni care permite unui website să recunoască un browser. Webserverul va recunoaște browserul până când cookie-ul expiră sau este șters. Cookie-ul stochează informații importante care îmbunătățesc experiența de navigare pe Internet (ex: setările limbii în care se dorește accesarea unui site; păstrarea unui user logat în contul de webmail; securitatea online banking; păstrarea produselor în coșul de cumpărături).</p>
            <p><strong>7. Linkuri utile</strong></p>
            <p>Dacă dorești să afli mai multe informații despre cookie-uri, îți recomandăm următoarele linkuri:</p>
            <p><a href="http://www.microsoft.com/info/cookies.mspx"> Microsoft Cookies guide </a><br/> <a href="http://www.allaboutcookies.org/">All About Cookies</a><br/> <a href="https://ro.wikipedia.org/wiki/Cookie">Wikipedia</a></p>
            <p>IAB România pune la dispoziție următorul site pentru a oferi mai multe informații privind<br/> confidențialitatea legată de publicitatea online: <a href="http://www.youronlinechoices.com/ro/"> http://www.youronlinechoices.com/ro/ </a></p>

        </div>
    );
};

export default CookiesPolicy;