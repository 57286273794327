import React from 'react';
import printerProblem from "../../../../img/stress-about.jpg";

const AboutUs = () => {
    return (
        <section id="about" className={'about section-bg'}>
            <div className={'container'} data-aos="fade-up">
                <div className={'section-title'}>
                <h2>Despre Noi</h2>
                <h3>Află mai multe <span>despre noi</span></h3>
                <p>Alege soluția intelegentă pentru viitorul tau business alături de Printech</p>
                </div>

                <div className={'row'}>
                <div className={'col-lg-6'} data-aos="fade-right" data-aos-delay="100">
                    <img src={printerProblem} className={'img-fluid'} alt="about"/>
                </div>
                <div className={'col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center'} data-aos="fade-up" data-aos-delay="100">
                    <p>
                        Ținem foarte mult la satisfacția clienților noștri, astfel încat ne dorim ca orice colaborare dintre clientii nostri si consultantii nostri sa fie la cele mai înalte standarde, si dorim sa ne asiguram ca indeplinim toate dorintele dumneavoastra.
                    </p>

                    <ul>
                    <li>
                        <i className={'bx bx-store-alt'}></i>
                        <div>
                        <h5>Pentru orice afacere</h5>
                        <p>Experienta de peste 17 ani in afaceri garanteaza ca suntem pregatiti sa te ajutam indiferent de ce nevoie ai.</p>
                        </div>
                    </li>
                    <li>
                        <i className={'bx bx-images'}></i>
                        <div>
                        <h5>Calitate ireproșabilă</h5>
                        <p>Toate echipamentele pe care le recomandam sunt certificate la standarde de calitate internațională.</p>
                        </div>
                    </li>
                    </ul>
                    <p>
                    Ne dorim să considerați că a meritat pe deplin să apelați la serviciile noastre, si din acest motiv va rugam sa veniti la noi cu orice intrebari care pot aparea inainte de o colaborare, in timpul colaborarii si de ce nu si dupa ce aceasta s-a incheiat.
                    </p>
                </div>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;