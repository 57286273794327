import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ContactForm from '../../contactForm';
import { constants, handleSubmit } from '../../../utils';
import { useToasts } from 'react-toast-notifications';
import epson1 from '../../../../img/epson/poza-epson.png';
import epson2 from '../../../../img/epson/poza-epson2.png';
import epson3 from '../../../../img/epson/epson3.png';

const OrderEpson = (props) => {
    const { addToast } = useToasts();
    const [pageProps, setPageProps] = useState({show: false, message: ''});
    const handleClose = () => setPageProps({show: false, message: pageProps.message});
    const handleShow = (msg) => {setPageProps({show: true, message: msg})};

    const handleSubmission = async (event) => {
        await handleSubmit(event).then(response => {
          if (response === 'success') {
              addToast('Email trimis cu succes!', {
                  appearance: 'success',
                  autoDismiss: true,
              });
          } else {
            addToast('Email-ul nu a putut fi trimis. Va rugam sa ne contactati telefonic!', {
              appearance: 'error',
              autoDismiss: true,
            });
          }
        });
    }

    return (
        <section className={'pricing'}>
            <div className={'container'} data-aos="fade-up">
                <div className={'section-title'}>
                    <h2>Oferte</h2>
                </div>

                <div className={'row my-4 justify-content-center'}>

                    <div className={'col-lg-4'} data-aos="fade-up" data-aos-delay="100">
                        <div className={'box'}>
                        <h3>Epson Work Force Pro WF-C579RDTWF</h3>
                        <h4><sup>€</sup>30 + TVA<span></span></h4>
                        <ul>
                            <p>A4, color, 24 ppm, duplex, network, wlan, NFC, wsd-scan, ftp scan, email scan, USB scan, capacitate totală hartie in casete 750 pagini, ecranul este tactil color, cerneala de arhivare rezistentă la apa, copy/scan/print/fax, consum de energie redus</p>
                        </ul>
                        <img className={'img-fluid'} src={epson1} alt='Epson'/>
                        <div className={'btn-wrap'}>
                            <Button onClick={(e) => handleShow(constants.EPSON_1_MESSAGE)} className={'btn-buy'}>Cere ofertă</Button>
                        </div>
                        </div>
                    </div>

                    <div className={'col-lg-4'} data-aos="fade-up" data-aos-delay="200">
                        <div className={'box'}>
                        <h3>Epson Work Force Pro WF-C878RDTWF</h3>
                        <h4><sup>€</sup>55 + TVA<span></span></h4>
                        <ul>
                            <p>
                                A3 color, 24 ppm, 24ipm scan, duplex, network, wlan, NFC, wsd-scan, ftp scan, email scan, USB scan, capacitate totală hartie în casete 750 pagini, ecran tactil color, cerneala de arhivare rezistentă la apa, copy/scan/print/fax, consum energie redus
                            </p>
                        </ul>
                        <img className={'img-fluid'} src={epson2} alt='Epson'/>
                        <div className={'btn-wrap'}>
                            <Button onClick={(e) => handleShow(constants.EPSON_2_MESSAGE)} className={'btn-buy'}>Cere ofertă</Button>
                        </div>
                        </div>
                    </div>

                    <div className={'col-lg-4'} data-aos="fade-up" data-aos-delay="200">
                        <div className={'box'}>
                        <h3>Epson Workforce Enterprise WF-C20750</h3>
                        <h4><sup>€</sup>135 + TVA</h4>
                        <ul>
                            <p>
                                Copy/scan/print/fax A3 color, 75 ppm, duplex, network, wlan, NFC, wsd-scan, ftp scan, email scan, USB scan, ecranul este tactil color, cerneala de arhivare rezistentă la apa, capacitate casete 5300 pagini, 1200 dpi, cu consum de energie redus
                            </p>
                        </ul>
                        <img className={'img-fluid'} src={epson3} alt='Epson'/>
                        <div className={'btn-wrap'}>
                            <Button onClick={(e) => handleShow(constants.EPSON_3_MESSAGE)} className={'btn-buy'}>Cere ofertă</Button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={pageProps.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Cerere ofertă</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ContactForm handleSubmit={handleSubmission} message={pageProps.message}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    );
};

export default OrderEpson;