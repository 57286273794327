import React from "react";
import { NavLink } from 'react-router-dom';

const Pricing = () => {
    return (
        <section id="pricing" className={'pricing'}>
            <div className={'container'} data-aos="fade-up">
                <div className={'section-title'}>
                    <h2>Închiriere Copiatoare</h2>
                    <h3>Oferim <span>Copiatoare</span> spre Închiriere</h3>
                    <p>Dacă aveți nevoie de achiziționarea un copiator, societatea noastră vă oferă acest echipament spre folosință, GRATUIT.<br/>
                        Toate costurile de întreținere a echipamentelor date spre folosință vor fi suportate de noi.</p>
                </div>

                <div className={'row'}>
                    <div className={'col-lg-4 col-md-6 mt-4'} data-aos="fade-up" data-aos-delay="100">
                        <div className={'box'}>
                            <h3>MICRO BUSINESS</h3>
                            <h4><sup>RON</sup>170</h4>
                            <ul>
                                <li><span>Copiator, Imprimantă, Scanner</span> </li>
                            </ul>

                            <div className={'btn-wrap'}>
                                <NavLink to='/copiatoare/micro' className={'btn-buy'}>Vezi Pachete</NavLink>
                            </div>
                        </div>
                    </div>

                    <div className={'col-lg-4 col-md-6 mt-4'} data-aos="fade-up" data-aos-delay="200">
                        <div className={'box'}>
                            <h3>SMALL BUSINESS</h3>
                            <h4><sup>RON</sup>240</h4>
                            <ul>
                                <li><span>Copiator, Imprimantă, Scanner</span> </li>
                                </ul>
                            <div className={'btn-wrap'}>
                                <NavLink to='/copiatoare/small' className={'btn-buy'}>Vezi Pachete</NavLink>
                            </div>
                        </div>
                    </div>

                    <div className={'col-lg-4 col-md-6 mt-4'} data-aos="fade-up" data-aos-delay="300">
                        <div className={'box'}>
                            <h3>MEDIUM BUSINESS</h3>
                            <h4><sup>RON</sup>350</h4>
                            <ul>
                                <li><span>Copiator, Imprimantă, Scanner</span> </li>
                            </ul>
                            <div className={'btn-wrap'}>
                                <NavLink to='/copiatoare/medium' className={'btn-buy'}>Vezi Pachete</NavLink>
                            </div>
                        </div>
                    </div>

                    <div className={'col-lg-4 col-md-6 mt-4'} data-aos="fade-up" data-aos-delay="300">
                        <div className={'box'}>
                            <h3>LARGE BUSINESS</h3>
                            <h4><sup>RON</sup>470</h4>
                            <ul>
                                <li><span>Copiator, Imprimantă, Scanner</span> </li>
                            </ul>
                            <div className={'btn-wrap'}>
                                <NavLink to='/copiatoare/large' className={'btn-buy'}>Vezi Pachete</NavLink>
                            </div>
                        </div>
                    </div>

                    <div className={'col-lg-4 col-md-6 mt-4'} data-aos="fade-up" data-aos-delay="300">
                        <div className={'box'}>
                            <h3>EXTRA LARGE BUSINESS</h3>
                            <h4><sup>RON</sup>780</h4>
                            <ul>
                               <li><span>Copiator, Imprimantă, Scanner</span> </li>
                            </ul>
                            <div className={'btn-wrap'}>
                                <NavLink to='/copiatoare/extra-large' className={'btn-buy'}>Vezi Pachete</NavLink>
                            </div>
                        </div>
                    </div>

                    <div className={'col-lg-4 col-md-6 mt-4'} data-aos="fade-up" data-aos-delay="400">
                        <div className={'box'}>
                            <h3>CORPORATE</h3>
                            <h4><sup>RON</sup>1190</h4>
                            <ul>
                                <li><span>Copiator, Imprimantă, Scanner</span> </li>
                            </ul>
                            <div className={'btn-wrap'}>
                                <NavLink to='/copiatoare/corporate' className={'btn-buy'}>Vezi Pachete</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Pricing;