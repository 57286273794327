import React from 'react';
import printerProblem from "../../img/printer-problem.png";

const AboutUs = () => {
    return (
        <section id="about" className={'about section-bg'}>
            <div className={'container'} data-aos="fade-up">
                <div className={'section-title'}>
                <h2>Despre Noi</h2>
                <h3>Află mai multe <span>despre noi</span></h3>
                <p>Alege soluția intelegentă pentru business-ul tău alături de Printech</p>
                </div>

                <div className={'row'}>
                <div className={'col-lg-6'} data-aos="fade-right" data-aos-delay="100">
                    <img src={printerProblem} className={'img-fluid'} alt="about"/>
                </div>
                <div className={'col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center'} data-aos="fade-up" data-aos-delay="100">
                    <p>
                        Ținem foarte mult la satisfacția clienților noștri, astfel încat ne dorim ca orice echipament reparat de noi să fie diagnosticat și reparat la cele mai înalte standarde. Dacă sunt cazuri mai speciale și reparația nu este sigură, preferăm să știți toate riscurile înaintea intervenției.
                    </p>

                    <ul>
                    <li>
                        <i className={'bx bx-store-alt'}></i>
                        <div>
                        <h5>Pentru toate buzunarele!</h5>
                        <p>Indiferent de bugetul tău, Printech Company îti dă posibilitatea să alegi dintr-o gamă variată de prețuri.</p>
                        </div>
                    </li>
                    <li>
                        <i className={'bx bx-images'}></i>
                        <div>
                        <h5>Calitate ireproșabilă</h5>
                        <p>Toate produsele sunt certificate la standarde de calitate internațională.</p>
                        </div>
                    </li>
                    </ul>
                    <p>
                    Ne dorim să considerați că a meritat pe deplin să apelați la serviciile noastre, că problema pentru care ati ajuns la noi în service s-a rezolvat și că de acum înainte știți la cine să apelați când se strică calculatorul, imprimanta sau orice alt echipament IT.
                    </p>
                </div>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;