import React from 'react';
import imgBanner from '../../../../img/banner-printech-1.jpg';

const PlanBanner = () => {
    return (
        <div className={'container'} data-aos="fade-up">
            <div className={'row d-flex align-items-center'}>
                <div className={'col-lg-6'} data-aos="fade-right" data-aos-delay="100">
                    <img src={imgBanner}
                         className={'img-fluid'} alt="banner"/>
                </div>
                <div className={'col-lg-6 content d-flex flex-column justify-content-center'} data-aos="fade-up"
                     data-aos-delay="100">
                    <p style={{marginBottom: "0px"}}>
                        Calitatea reprezintă o parte importantă a produselor și serviciilor PRINTECH COMPANY, de aceea
                        oferim numai cartușe noi și de calitate superioară astfel încât echipamentele să printeze la
                        calitate maximă.
                        <br/><br/>
                        Service-ul de mententanta și cel de intervenție specializat sunt asigurate în cadrul
                        contractului de închiriere, fără costuri suplimentare. De asemenea, dacă reparația
                        echipamentului durează mai mult de 48 de ore, vă punem la dispoziție echipament de back-up.
                        Hârtia nu este inclusă în costurile contractului. Ea este asigurată de Custode (Depozitar).
                        <br/>
                    </p>
                    <ul>
                        <li>
                            Prețurile sunt exprimate în lei și nu conțin TVA;
                        </li>
                        <li>
                            Livrarea se face pe baza unei comenzi ferme a produselor, în aproximativ 3 zile.
                        </li>
                    </ul>
                    <p style={{marginBottom: "0px"}}>
                        Pentru o ofertă personalizată vă rugam să ne contactați.
                    </p>

                </div>
            </div>
        </div>
    );
};

export default PlanBanner;