import React from 'react';
import { NavLink } from 'react-router-dom';

const Footer = () => {
    return (
        <footer id="footer">

            <div className={'footer-top'}>
                <div className={'container'}>
                    <div className={'row'}>

                    <div className={'col-lg-3 col-md-6 footer-contact'}>
                        <h3>Printech</h3>
                        <p>
                            Str. 14 Octombrie, nr.46, Târgu-Jiu, Gorj<br/>
                            Str. George Emil Palade, nr.93, Oradea, Bihor <br/><br/>
                            <strong>Phone:</strong> 0353416464<br/>
                            <strong>Email:</strong> office@printechcompany.ro<br/>
                        </p>
                    </div>

                    <div className={'col-lg-3 col-md-6 footer-links'}>
                        <h4>Link-uri Utile</h4>
                        <ul>
                            <li><i className={'bx bx-chevron-right'}></i> <NavLink className={'scrollto'} to="/">Acasă</NavLink></li>
                            <li><i className={'bx bx-chevron-right'}></i> <NavLink className={'scrollto'} to='/#about'>Despre noi</NavLink></li>
                            <li><i className={'bx bx-chevron-right'}></i> <NavLink className={'scrollto'} to='/#services'>Servicii</NavLink></li>
                            <li><i className={'bx bx-chevron-right'}></i> <NavLink to='/termeni-si-conditii'>Termeni și condiții</NavLink></li>
                            <li><i className={'bx bx-chevron-right'}></i> <NavLink to='/politica-cookies'>Politica cookies</NavLink></li>
                        </ul>
                    </div>

                    <div className={'col-lg-3 col-md-6 footer-links'}>
                        <h4>Ne găsești pe rețelele sociale</h4>
                        <div className={'social-links mt-3'}>
                            <a href="https://www.facebook.com/PrintechCompany" className={'facebook'}><i className={'bx bxl-facebook'}></i></a>
                            <a href="https://www.instagram.com/printechcompany/" className={'instagram'}><i className={'bx bxl-instagram'}></i></a>
                        </div>
                    </div>

                    </div>
                </div>
            </div>

            <div className={'container py-4'}>
                <div className={'copyright'}>
                    &copy; Copyright <strong><span>Printech</span></strong>. Toate Drepturile Rezervate. Realizat de <a href="https://dotdot.ro/" style={{textDecoration: "none", color: "inherit"}}><strong>dot.</strong></a>
                </div>
                <div className={'credits'}>
                </div>
            </div>
        </footer>
    );
}

export default Footer;